import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/auth/authContext";

const events = ["keypress", "mousemove", "touchmove", "click", "scroll"];

const useActive = (time) => {
  const { globalSesion, globalInactivaSesion, globalEnLlamada } = useContext(AuthContext);
  const [active, setActive] = useState(true);
  const timer = useRef(null);

  const startTimer = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      setActive(false);
      if (!globalSesion) {
        globalInactivaSesion();
      }
    }, time);
  };

  useEffect(() => {
    const handleEvent = () => {
      if (globalEnLlamada) return;

      setActive(true);
      startTimer(); // Reiniciar el temporizador al detectar actividad.
    };

    // Agregar eventos solo si no está en llamada.
    if (!globalEnLlamada) {
      startTimer(); // Iniciar el temporizador en el primer render.
      events.forEach((event) => {
        document.addEventListener(event, handleEvent);
      });
    }

    return () => {
      // Limpiar eventos y temporizador.
      events.forEach((event) => {
        document.removeEventListener(event, handleEvent);
      });
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [time, globalEnLlamada]); // Dependencia de `globalEnLlamada` para detener/reanudar.

  useEffect(() => {
    // Detener el temporizador si está en llamada.
    if (globalEnLlamada && timer.current) {
      window.clearTimeout(timer.current);
      timer.current = null;
    }
  }, [globalEnLlamada]);

  return active;
};

export default useActive;
