import React from 'react'
import UIStepBlurp from '../../../../components/UIStepBlurp'
import '../styles/homeOutCompania.css'

const PrincipalPasos = () => {
    return (
        <div>
            <h3 className='out__compania-title'>¿Cómo puedes usar tus servicios?</h3>
            <p className='inicia__subtitulo-detalle' >Solo sigue 3 simples pasos: </p>
            <div className='pasos__group' style={{ justifyContent: 'center' }} >
                <UIStepBlurp
                    ico="/assets/img/homeout/ico_homeout_process1.webp"
                    title="Haz clic"
                    description="Da clic en el botón “Activa tu cuenta”"
                />
                <div className='out__process-divider' />
                <UIStepBlurp
                    ico="/assets/img/homeout/ico_homeout_process2.webp"
                    title="Completa"
                    description="Ingresa tus datos en el formulario."
                />
                <div className='out__process-divider' />

                <UIStepBlurp
                    ico="/assets/img/homeout/ico_homeout_process3.webp"
                    title="¡Disfruta!"
                    description="¡Usa los beneficios que tenemos para tí!"
                />

            </div>
        </div>
    )
}

export default PrincipalPasos