import React, { useState } from 'react'

const PrincipalPregunta = ({titulo, texto,texto2='',texto3=''}) => {

    const [preguntaActiva, setPreguntaActiva] = useState(false)

    const mostrarPregunta = () => {
        setPreguntaActiva(!preguntaActiva)
    }

    return (
        <div className="preguntas__group">
            <div onClick={mostrarPregunta} className={`pregunta__titulo ${preguntaActiva ? 'remarcado' : ''}`}>
                <h4 style={{fontFamily:'Scotia-Headline', fontSize:'18px'}}>{titulo}</h4>
                {
                    preguntaActiva
                        ? <i className="fa-solid fa-angle-up" style={{ color: 'var(--rojo)' }}></i>
                        : <i className="fa-solid fa-angle-down" style={{ color: 'var(--rojo)' }}></i>
                }
            </div>
            {
                preguntaActiva &&
                <div className='pregunta__detalle'>
                    <p>{texto}</p>
                    <p>{texto2}</p>
                    <p>{texto3}</p>
                </div>
            }
        </div>
    )
}

export default PrincipalPregunta