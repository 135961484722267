import React from 'react'


const PrincipalContactoContactos = () => {

    let linkFace = 'https://www.facebook.com/profile.php?id=100063739894626'
    let linkLinked = 'https://www.linkedin.com/showcase/cardif-m%C3%A9xico'

    const abrirLink = (link) =>{
        window.open(link, '_blank', 'noopener,noreferrer')
        
    }

    const sendSMSEmail = () =>{
        window.location = 'mailto:contacto@vivetuseguro.com'
    }

    const makeCall = () =>{
        window.location = 'tel:8008012402'
    }



    return (
        <div className="contacto__contactanos" id="contactoFooter">
            <div className="contacto__contactanos-email" style={{fontFamily:'Scotia-Headline'}}>

                <p>Contáctanos:</p>
                <p><span onClick={()=>{sendSMSEmail()}} style={{cursor:"pointer"}} >contacto@vivetuseguro.com</span></p>
            </div>

            <div className="contacto__contactanos-llamada">
                <div className="contactanos__llamada-group">
                    <div className="contactanos__llamada">
                        <p>Atención a Clientes Cardif:&nbsp;
                            <span onClick={()=>{makeCall()}} style={{cursor:"pointer"}} className='llamar-telefono-mobile'>800 801 2402</span>
                        </p>
                    </div>
                    {/* <div className="contactanos__llamada">
                        <p>Línea CrediScotia:</p>
                        <p>(01) 615-5708</p>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default PrincipalContactoContactos