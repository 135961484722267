import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const OutEcosistemasCards = ({
  ecoDetalle,
  index,
  color,
  nombreEcosistema,
}) => {
  const navigate = useNavigate();
  const test = ecoDetalle.detail_eco === "EM" || ecoDetalle.detail_eco === "VP" || ecoDetalle.detail_eco === "SF"
  console.log(test)

  return (
    <div className="eco__card" style={{ height: '361px' }}>
      <div>
        <div className="eco__card-img" style={{ height: '170.33px' }}>
          <img
            className="eco__card-icoprincipal"
            src={ecoDetalle.imgmini}
            alt="imagen eco"
          />
        </div>
        <div className="eco__card-texto">
          <div className="eco__card-superior">
            <h4
              style={{ marginBottom: "1em" }}
              className={`eco__card-title  ${color}`}
            >
              {ecoDetalle.title}
            </h4>
            {/*<h4 className={`eco__card-title`}>{index + 1}. {ecoDetalle.title}</h4>*/}
            {(ecoDetalle.id >= 38 && ecoDetalle.id <= 45) ||
            ecoDetalle.id === 59 || ecoDetalle.detail_eco === "EL" || ecoDetalle.detail_eco === "PF" || ecoDetalle.detail_eco === "EM" ? (
              <></>
            ) : (
              <p
                className="eco__card-subtitulo"
                style={{
                  fontFamily: "Scotia",
                  fontWeight: "bold",
                }}
              >
                {ecoDetalle.subtitle}
              </p>
            )}

            <p
              style={{ textAlign: "left" }}
              className="eco__card-descripcion"
              dangerouslySetInnerHTML={{ __html: ecoDetalle.desc }}
            ></p>
          </div>
        </div>
      </div>
      {ecoDetalle.id === 59 ||
        (ecoDetalle.id >= 60 && ecoDetalle.id <= 83) ||
        nombreEcosistema.includes("Cuidados") || ecoDetalle.detail_eco === "PF" || ecoDetalle.detail_eco === "EL" || ecoDetalle.detail_eco === "EM" ? (
        <>
          <br />
        </>
      ) : (
        <div className="eco__card-btn">
          <button
            className={`vermas__btn`}
            onClick={() => {
              navigate(ecoDetalle.linkOut);
            }}
          >
            Ver más
          </button>
        </div>
      )}
    </div>
  );
};

export default OutEcosistemasCards;
