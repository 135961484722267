import React, { useContext } from 'react'
import useBotons from './useBotons'
import './ecosistemas.css'
import AuthContext from '../../context/auth/authContext'


const useOutEcosistema = () => {

    const { globalEcosistemas } = useContext(AuthContext)
    const { unirmeBtn, activaCuentaBtn } = useBotons()


    


    const ecosistemaPresentacion = (id = 1) => {
        let ecoSelected = globalEcosistemas.filter(e => e.id === id)[0]
        return (
            <section className="eco__presentacion">

                <div className='eco__presentacion-textos'>
                    <div>
                        <h2 className='eco__textos-title'>Tu seguro <span>{ecoSelected.name}</span> te trae</h2>
                        <h2 className='eco__textos-subtitle'>{ecoSelected.eco}</h2>
                        <p className='eco__textos-descrip'>{ecoSelected.desc}</p>
                        <div className='proteccion__btns'>
                            {activaCuentaBtn(ecoSelected.color)}
                            {unirmeBtn(ecoSelected)}
                        </div>
                    </div>
                </div>

                <div className={`eco__presentacion-img ${ecoSelected.color}`}>
                    <div>
                        <img src={ecoSelected.img} alt="imagen ecosistema" />                        
                    </div>
                </div>

            </section>
        )
    }




    const ecosistemaPresentacionOut = (id = 1) => {
        let ecoSelected = globalEcosistemas.filter(e => e.id === id)[0]

        

        const titulo = (id)=>{
            if (id === 12)
            {
                return(<h2 className='eco__textos-title'>Tu seguro de Apoyo para gastos médicos  te da más beneficios</h2>)
            }
            else
            {
                return ((ecoSelected.id === 8 || ecoSelected.id === 9 || ecoSelected.id === 11 || ecoSelected.id === 13 || ecoSelected.id === 15) ? <h2 className='eco__textos-title'>Tu seguro Protección Financiera te da más beneficios</h2> : <h2 className='eco__textos-title'>Tu seguro te da más beneficios</h2>)
            }
        }

        return (
            <section className="eco__presentacion" style={{ gap: "0"}}>

                <div className='eco__presentacion-textos izquierda' style={{ justifyContent: 'center', marginTop: ecoSelected.id === 8 && "1rem" }}>
                    <div >
                        {titulo(ecoSelected.id)}
                        <h2 className={`eco__textos-subtitle ${ecoSelected.color}`}>{ecoSelected.eco === "Emprendimiento" ? "¡Descúbrelos!" : ecoSelected.eco}</h2>
                        <p className='eco__textos-descrip' style={{whiteSpace: 'pre-wrap', fontWeight: '400', fontSize: '18px'}}>{ecoSelected.desc}</p>
                        <div className='proteccion__btns'>
                            {activaCuentaBtn(ecoSelected.color)}
                        </div>
                    </div>
                </div>
                <div className='eco__presentacion-img d-block'>
                    <div className='desktop'>
                        <img src={
                            ecoSelected.id === 8 
                            ? '/assets/img/ecosistemas/proteccion/img_proteccion_in_component.webp' 
                            : ecoSelected.id === 3
                            ? '/assets/img/ecosistemas/empleabilidad/img_empleabilidad_in_component.webp'
                            : ecoSelected.id === 2
                            ? '/assets/img/ecosistemas/emprendimiento/img_emprendimiento_in_component.webp'
                            : ecoSelected.img
                            }
                            style={{ height: "43.7rem" }}
                            alt="imagen ecosistema" />
                    </div>
                </div>

            </section>
        )
    }


    return { ecosistemaPresentacion, ecosistemaPresentacionOut }
}

export default useOutEcosistema