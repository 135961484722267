import { Modal } from "react-bootstrap"
import { IconWarning } from "./Icons"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { getCoursera } from "../../../services/cursera"
import { getToken } from "../../helpers/validations"
import { programasCoursera } from "../../helpers/data"
import { addLogs } from "../../../services/logs_proteccion"

const ModalSaltoCoursera = ({show, setShow, color, globalDataUser, globalInactivaSesion, ecoPadre}) => {

    const handleClose = () => setShow(false)

    const [refreshCoursera, setRefreshCoursera] = useState(true)
    const [linkCoursera, setLinkCoursera] = useState(null)

    const gotoCourseraClicked = () => {
        addLogs('Coursera', 'Usuario entra a Coursera en ' + ecoPadre.name, '')
        handleClose()
    }

    setInterval(() => {
        setRefreshCoursera(true);
    }, 60000);

    useEffect(() => {
        if (!refreshCoursera) return
        console.log(ecoPadre)
        let idProgramaEcosistema = 1
        switch (ecoPadre.name) {
            case "Empleabilidad":
                idProgramaEcosistema = 2;
                break;
            case "Emprendimiento":
                idProgramaEcosistema = 3;
                break;
            case "Protección Financiera":
                idProgramaEcosistema = 5;
                break;
            default:
                break;
        }
        try {
            getCoursera(
                globalDataUser.globalUsuId,
                getToken(),
                ecoPadre.eco,
                programasCoursera.find((pc) => pc.id === idProgramaEcosistema)
            ).then(([nuevaRuta, status]) => {

                if (status === 401) {
                    globalInactivaSesion();
                    return;
                }
                if (nuevaRuta?.includes("/")) {
                    setLinkCoursera(nuevaRuta)
                    setRefreshCoursera(false);

                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: "Inconvenientes en la conexión",
                    text: "Volver a intentar en unos instantes.",
                });
            });
            } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Inconvenientes en la conexión",
                text: "Actualizar página o volver a intentar en unos instantes.",
            });
            // console.log(error)
        }
    }, [refreshCoursera])

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div style={{ height: "40px" }}>
                    <button
                        id="btnCierraModal"
                        type="button"
                        className="close-modal btnTermsClosePoli ajusteXmodal newCloseModalButton"
                        onClick={handleClose}
                    >
                        <p className="iconCloseTerms">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#111111" className="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </p>
                    </button>
                </div>
                <div className="div__modal__coursera">
                    <div style={{ paddingBottom: "3rem" }}>
                        <IconWarning color={`var(--${color})`} />
                    </div>
                    <h1 style={{ color: `var(--${color})` }}>Vas a salir del sitio</h1>
                    <p>Para acceder a Coursera serás dirigido a un sitio externo a Vive tu Seguro, si estas de acuerdo da clic en Aceptar, de lo contrario cierra esta ventana.</p>
                    <div className="btn__modal__coursera">
                        <a
                            href={linkCoursera}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={gotoCourseraClicked}
                            style={{
                                backgroundColor: `var(--${color})`,
                                color: '#fff',
                                borderColor: `var(--${color})`,
                                fontSize: "17px",
                                width: "24rem"
                            }}
                        >Aceptar
                        </a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalSaltoCoursera