import React from 'react'
import PrincipalPregunta from './PrincipalPregunta'
import '../styles/homeOutPreguntas.css'
import { HashLink } from 'react-router-hash-link'

const PrincipalPreguntas = () => {

    return ( 
        <div className='out__preguntas'>
            <div className="preguntas__titulo">
                <h3>Preguntas Frecuentes</h3>
                <p>¿Tienes dudas sobre los beneficios de tu seguro?</p>
            </div>
            <div className="preguntas__listado">
                <PrincipalPregunta 
                    titulo='¿Cómo puedo usar los beneficios gratuitos de mi seguro?'
                    texto='1. Verifica que cuentes con una póliza vigente.'
                    texto2='2. Verifica que tu cuenta está activa.'
                    texto3='Si tienes problemas de acceso llama al: 800 801 2402 opción 3'
                />
                <PrincipalPregunta
                    titulo='¿Acceder a los beneficios tiene un costo adicional al seguro contratado?'
                    texto='No, los beneficios no generan costos adicionales a tu seguro contratado ya que forman parte de los beneficios de tu seguro.'
                />
                <PrincipalPregunta
                    titulo='¿Si no cuento con un seguro, cómo puedo contratarlo?'
                    texto='Acércate a cualquiera de las sucursales Scotiabank en el país y pregunta por el seguro que más se adapte a tus necesidades.                    '
                />
            </div>
        </div>
    )
}

export default PrincipalPreguntas