import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FallbackCards from "../../modules/helpers/FallbackCards";

const EmpleabilidadCvDesign = React.lazy(() =>
  import(
    "../../modules/homein/empleabilidad/components/curriculum/EmpleabilidadCvDesign"
  )
);
const EmpleabilidadCvAsesoria = React.lazy(() =>
  import(
    "../../modules/homein/empleabilidad/components/curriculum/EmpleabilidadCvAsesoria"
  )
);
const RouterEmpleabilidadJob = React.lazy(() =>
  import("./RouterEmpleabilidadJob")
);
const RouterEmpleabilidadSalario = React.lazy(() =>
  import("./RouterEmpleabilidadSalario")
);
const RouterEmpleabilidadLegal = React.lazy(() =>
  import("./RouterEmpleabilidadLegal")
);
const EmpleabilidadCoursera = React.lazy(() =>
  import("../../modules/homein/empleabilidad/components/EmpleabilidadCoursera")
);
const EmpleabilidadEntrevistaAsesoria = React.lazy(() =>
  import(
    "../../modules/homein/empleabilidad/components/entrevista/EmpleabilidadEntrevistaAsesoria"
  )
);
const AgendamientoCalifica = React.lazy(() => 
  import(
    "../../modules/hooks/components/AgendamientoCalifica"
  )
);
const EmpleabilidadEntrevistaPsicotecnica = React.lazy(() =>
  import(
    "../../modules/homein/empleabilidad/components/entrevista/EmpleabilidadEntrevistaPsicotecnica"
  )
);
const EmpleabilidadScreen = React.lazy(() =>
  import("../../modules/homein/empleabilidad/screens/EmpleabilidadScreen")
);
const PageNotFound = React.lazy(() => import("../../modules/404/PageNotFound"));

const RouterEmpleabilidad = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route path="/coursera" element={<EmpleabilidadCoursera />} />
          <Route path="/curriculum/*" element={<EmpleabilidadCvDesign />} />
          <Route
            path="/marca-personal/*"
            element={<EmpleabilidadCvAsesoria />}
          />
          <Route path="/job/*" element={<RouterEmpleabilidadJob />} />
          <Route path="/salario/*" element={<RouterEmpleabilidadSalario />} />
          <Route path="/legal/*" element={<RouterEmpleabilidadLegal />} />
          <Route
            path="/entrevista"
            element={<EmpleabilidadEntrevistaAsesoria />}
          />
          <Route
            path="/habilidades"
            element={<EmpleabilidadEntrevistaPsicotecnica />}
          />
          <Route 
            path="/califica"
            element={<AgendamientoCalifica color={"morado"}/>}
          />
          <Route path="/" element={<EmpleabilidadScreen />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterEmpleabilidad;
