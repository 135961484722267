import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import useFormValidator from "../../../hooks/useFormValidator";

const PrincipalPresentacionForm = () => {
  const { globalActualizarDocumentoUsuario } = useContext(AuthContext);
  const { validarFormLogin, errors } = useFormValidator();
  const [document, setDocument] = useState("");
  const navigate = useNavigate();

  const handleChangeForm = (e) => {
    setDocument(e.target.value);
    // validarFormLogin(['document', e.target.value])
  };
  const onSubmitPrincipalForm = (e) => {
    e.preventDefault();

    if (!document || !errors.document) {
      globalActualizarDocumentoUsuario(document);
      navigate("/registro", { state: { document } });
      return;
    }
  };

  return (
    <form onSubmit={onSubmitPrincipalForm}>
      <div className="out__principalpresentacion-activar">
        <input
          type="text"
          placeholder="Ingresa tu Correo"
          value={document}
          onChange={handleChangeForm}
        />
        <button type="submit">Activa tu cuenta</button>
      </div>
      <p className="out__principalpresentacion-error">{errors.document}</p>
    </form>
  );
};

export default PrincipalPresentacionForm;
