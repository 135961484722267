export const IconGeoAltFill = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
        </svg>
    )
}

export const IconArrowCard = ({color}) => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.34315 0.928932C6.95262 0.538408 6.31946 0.538408 5.92893 0.928932C5.53841 1.31946 5.53841 1.95262 5.92893 2.34315L11.5858 8L5.92893 13.6569C5.53841 14.0474 5.53841 14.6805 5.92893 15.0711C6.31946 15.4616 6.95262 15.4616 7.34315 15.0711L13.7071 8.70711ZM0 9H13V7H0V9Z" fill={color}/>
        </svg>
    )
}

export const IconWarning = ({color}) => {
    return (
        <svg width="55" height="59" viewBox="0 0 55 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.025 2.33602L54.4547 42.9159C54.8119 43.5347 55 44.2366 55 44.9511C55 45.6656 54.8119 46.3675 54.4547 46.9863C54.0974 47.6051 53.5836 48.119 52.9648 48.4762C52.3461 48.8335 51.6441 49.0216 50.9296 49.0216H4.07037C3.35586 49.0216 2.65394 48.8335 2.03517 48.4762C1.41639 48.119 0.90256 47.6051 0.545314 46.9863C0.188067 46.3675 -4.53789e-06 45.6656 0 44.9511C4.53805e-06 44.2366 0.188085 43.5347 0.54534 42.9159L23.975 2.33602C25.5407 -0.377631 29.4565 -0.377631 31.025 2.33602ZM27.5 7.0849L6.42039 43.5943H48.5796L27.5 7.0849ZM27.5 34.4982C28.2197 34.4982 28.9099 34.7841 29.4188 35.293C29.9277 35.8019 30.2136 36.4921 30.2136 37.2118C30.2136 37.9315 29.9277 38.6217 29.4188 39.1306C28.9099 39.6396 28.2197 39.9255 27.5 39.9255C26.7803 39.9255 26.0901 39.6396 25.5812 39.1306C25.0723 38.6217 24.7864 37.9315 24.7864 37.2118C24.7864 36.4921 25.0723 35.8019 25.5812 35.293C26.0901 34.7841 26.7803 34.4982 27.5 34.4982ZM27.5 15.5026C28.2197 15.5026 28.9099 15.7885 29.4188 16.2974C29.9277 16.8064 30.2136 17.4966 30.2136 18.2163V29.0709C30.2136 29.7906 29.9277 30.4808 29.4188 30.9897C28.9099 31.4986 28.2197 31.7845 27.5 31.7845C26.7803 31.7845 26.0901 31.4986 25.5812 30.9897C25.0723 30.4808 24.7864 29.7906 24.7864 29.0709V18.2163C24.7864 17.4966 25.0723 16.8064 25.5812 16.2974C26.0901 15.7885 26.7803 15.5026 27.5 15.5026Z" fill={color}/>
        </svg>
    )
}

export const IconUsuario = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3201_9544)">
                <path d="M13.914 26.2329C13.7512 26.2329 13.5964 26.2289 13.4431 26.2226C10.8032 26.1562 8.23751 25.3356 6.04907 23.8577C5.16179 23.2661 4.37538 22.5357 3.71992 21.6945L3.35939 22.4148C1.66023 20.4839 0.547678 18.1081 0.152542 15.5666C-0.242593 13.0251 0.0961177 10.4236 1.12885 8.06806C2.16158 5.71248 3.8454 3.70072 5.98232 2.26934C8.11924 0.837952 10.6204 0.0464438 13.1918 -0.0121218C15.7631 -0.0706874 18.2978 0.606125 20.4976 1.93874C22.6975 3.27135 24.4712 5.20435 25.6101 7.51047C26.749 9.81659 27.2058 12.3999 26.9268 14.9568C26.6478 17.5136 25.6445 19.9377 24.035 21.9439L23.8294 20.8869V20.8932C23.1148 21.8975 22.2613 22.7954 21.2945 23.5599C20.3517 24.3186 19.3043 24.9372 18.1848 25.3967C16.8292 25.9497 15.3789 26.2335 13.9148 26.2321L13.914 26.2329ZM13.4995 18.2663C12.3518 18.2631 11.215 18.4876 10.1546 18.9266C9.09427 19.3657 8.13151 20.0106 7.32204 20.8242C6.76889 21.3767 6.29192 22.0006 5.90375 22.6792C8.03992 24.4474 10.7261 25.4149 13.4991 25.4149C16.2721 25.4149 18.9582 24.4474 21.0944 22.6792C20.7059 22.0006 20.2287 21.3767 19.6753 20.8242C18.8662 20.0107 17.9037 19.3657 16.8436 18.9267C15.7835 18.4876 14.6469 18.2632 13.4995 18.2663ZM13.4995 16.6781C15.2564 16.6773 16.9845 17.1251 18.5199 17.9793C20.0553 18.8334 21.3471 20.0655 22.2729 21.5587C23.8368 19.8551 24.871 17.7331 25.2492 15.4517C25.6275 13.1702 25.3335 10.828 24.403 8.71082C23.4726 6.59365 21.946 4.79311 20.0095 3.52892C18.0731 2.26472 15.8105 1.59155 13.4979 1.59155C11.1853 1.59155 8.92271 2.26472 6.98625 3.52892C5.04978 4.79311 3.52318 6.59365 2.59275 8.71082C1.66232 10.828 1.36829 13.1702 1.74655 15.4517C2.1248 17.7331 3.15899 19.8551 4.72289 21.5587C5.64893 20.065 6.94124 18.8326 8.47723 17.9784C10.0132 17.1242 11.742 16.6766 13.4995 16.6781Z" fill="#707070"/>
                <path d="M13.5 1.58824C11.144 1.58811 8.84084 2.28666 6.88185 3.59553C4.92285 4.9044 3.39599 6.76481 2.49437 8.94148C1.59274 11.1181 1.35685 13.5133 1.81652 15.824C2.27619 18.1348 3.41078 20.2573 5.07679 21.9232C7.31077 24.1572 10.3407 25.4122 13.5 25.4122C16.6593 25.4122 19.6892 24.1572 21.9232 21.9232C24.1572 19.6892 25.4122 16.6593 25.4122 13.5C25.4122 10.3407 24.1572 7.31077 21.9232 5.07679C20.8197 3.96717 19.5069 3.08748 18.0611 2.48867C16.6152 1.88985 15.0649 1.5838 13.5 1.58824ZM13.5 0C16.17 0 18.7801 0.791761 21.0002 2.27516C23.2203 3.75856 24.9506 5.86697 25.9724 8.33377C26.9942 10.8006 27.2615 13.515 26.7406 16.1337C26.2197 18.7525 24.934 21.1579 23.0459 23.0459C21.1579 24.934 18.7525 26.2197 16.1337 26.7406C13.515 27.2615 10.8006 26.9942 8.33377 25.9724C5.86697 24.9506 3.75856 23.2203 2.27516 21.0002C0.791761 18.7801 0 16.17 0 13.5C0 9.91958 1.42232 6.4858 3.95406 3.95406C6.4858 1.42232 9.91958 0 13.5 0Z" fill="#707070"/>
                <path d="M13.4991 7.14683C12.8708 7.14683 12.2567 7.33313 11.7343 7.68216C11.212 8.0312 10.8048 8.52729 10.5644 9.10772C10.324 9.68814 10.2611 10.3268 10.3836 10.943C10.5062 11.5592 10.8087 12.1252 11.253 12.5694C11.6972 13.0136 12.2632 13.3162 12.8794 13.4387C13.4956 13.5613 14.1342 13.4984 14.7147 13.258C15.2951 13.0176 15.7912 12.6104 16.1402 12.0881C16.4893 11.5657 16.6756 10.9515 16.6756 10.3233C16.6756 9.48085 16.3409 8.6729 15.7452 8.0772C15.1495 7.48149 14.3415 7.14683 13.4991 7.14683ZM13.4991 5.55859C14.4415 5.55859 15.3627 5.83804 16.1462 6.36159C16.9298 6.88514 17.5405 7.62929 17.9011 8.49993C18.2617 9.37056 18.3561 10.3286 18.1722 11.2528C17.9884 12.1771 17.5346 13.0261 16.8682 13.6925C16.2019 14.3588 15.3529 14.8126 14.4286 14.9965C13.5044 15.1803 12.5463 15.0859 11.6757 14.7253C10.8051 14.3647 10.0609 13.754 9.53737 12.9704C9.01382 12.1869 8.73438 11.2657 8.73438 10.3233C8.73438 9.05962 9.23637 7.8477 10.1299 6.95414C11.0235 6.06059 12.2354 5.55859 13.4991 5.55859Z" fill="#707070"/>
            </g>
            <defs>
                <clipPath id="clip0_3201_9544">
                    <rect width="27" height="27" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const IconDisclaimerAlert = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5013 30.0846C7.44693 30.0846 0.917969 23.5557 0.917969 15.5013C0.917969 7.44693 7.44693 0.917969 15.5013 0.917969C23.5557 0.917969 30.0846 7.44693 30.0846 15.5013C30.0846 23.5557 23.5557 30.0846 15.5013 30.0846ZM15.5013 27.168C18.5955 27.168 21.563 25.9388 23.7509 23.7509C25.9388 21.563 27.168 18.5955 27.168 15.5013C27.168 12.4071 25.9388 9.43965 23.7509 7.25172C21.563 5.0638 18.5955 3.83464 15.5013 3.83464C12.4071 3.83464 9.43965 5.0638 7.25172 7.25172C5.0638 9.43965 3.83464 12.4071 3.83464 15.5013C3.83464 18.5955 5.0638 21.563 7.25172 23.7509C9.43965 25.9388 12.4071 27.168 15.5013 27.168ZM15.5013 8.20963C15.8881 8.20963 16.259 8.36328 16.5325 8.63677C16.806 8.91026 16.9596 9.28119 16.9596 9.66797V16.9596C16.9596 17.3464 16.806 17.7173 16.5325 17.9908C16.259 18.2643 15.8881 18.418 15.5013 18.418C15.1145 18.418 14.7436 18.2643 14.4701 17.9908C14.1966 17.7173 14.043 17.3464 14.043 16.9596V9.66797C14.043 9.28119 14.1966 8.91026 14.4701 8.63677C14.7436 8.36328 15.1145 8.20963 15.5013 8.20963ZM15.5013 22.793C15.1145 22.793 14.7436 22.6393 14.4701 22.3658C14.1966 22.0923 14.043 21.7214 14.043 21.3346C14.043 20.9479 14.1966 20.5769 14.4701 20.3034C14.7436 20.0299 15.1145 19.8763 15.5013 19.8763C15.8881 19.8763 16.259 20.0299 16.5325 20.3034C16.806 20.5769 16.9596 20.9479 16.9596 21.3346C16.9596 21.7214 16.806 22.0923 16.5325 22.3658C16.259 22.6393 15.8881 22.793 15.5013 22.793Z" fill="black"/>
        </svg>
    )
}

export const IconDisclaimerClose = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 32C25.2845 32 32 25.2845 32 17C32 8.7155 25.2845 2 17 2C8.7155 2 2 8.7155 2 17C2 25.2845 8.7155 32 17 32Z" stroke="black" stroke-width="4" stroke-linejoin="round"/>
            <path d="M21.2433 12.7578L12.7578 21.2433M12.7578 12.7578L21.2433 21.2433" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const IconSuccess = ({color}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.98472 13.2242L4.32031 9.55894L5.54149 8.33776L7.98472 10.7801L12.8694 5.89453L14.0915 7.11658L7.98472 13.2225V13.2242Z" fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.5C0 4.25341 4.25341 0 9.5 0C14.7466 0 19 4.25341 19 9.5C19 14.7466 14.7466 19 9.5 19C4.25341 19 0 14.7466 0 9.5ZM9.5 17.2727C8.47927 17.2727 7.46854 17.0717 6.52551 16.6811C5.58248 16.2904 4.72562 15.7179 4.00385 14.9961C3.28209 14.2744 2.70955 13.4175 2.31894 12.4745C1.92832 11.5315 1.72727 10.5207 1.72727 9.5C1.72727 8.47927 1.92832 7.46854 2.31894 6.52551C2.70955 5.58248 3.28209 4.72562 4.00385 4.00385C4.72562 3.28209 5.58248 2.70955 6.52551 2.31894C7.46854 1.92832 8.47927 1.72727 9.5 1.72727C11.5615 1.72727 13.5385 2.54618 14.9961 4.00385C16.4538 5.46152 17.2727 7.43854 17.2727 9.5C17.2727 11.5615 16.4538 13.5385 14.9961 14.9961C13.5385 16.4538 11.5615 17.2727 9.5 17.2727Z" fill={color}/>
        </svg>
    )
}

export const IconClose = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6884 15.9485L12.3554 9.6115L18.6884 3.2785C19.0486 2.91834 19.2509 2.42985 19.2509 1.9205C19.2509 1.41115 19.0486 0.922666 18.6884 0.562502C18.3282 0.202338 17.8397 1.00405e-08 17.3304 0C16.821 -1.00405e-08 16.3326 0.202338 15.9724 0.562502L9.6394 6.8995L3.3064 0.566502C2.94623 0.206338 2.45775 0.00399971 1.9484 0.00399971C1.43905 0.00399971 0.950561 0.206338 0.590397 0.566502C0.230233 0.926666 0.027895 1.41515 0.027895 1.9245C0.027895 2.43385 0.230233 2.92234 0.590397 3.2825L6.9234 9.6115L0.590397 15.9445C0.404104 16.1183 0.255553 16.3285 0.153984 16.5621C0.0524152 16.7957 0 17.0478 0 17.3025C0 17.5572 0.0524152 17.8093 0.153984 18.0429C0.255553 18.2765 0.404104 18.4867 0.590397 18.6605C0.767885 18.8403 0.979326 18.9831 1.21245 19.0806C1.44557 19.1781 1.69572 19.2282 1.9484 19.2282C2.20107 19.2282 2.45123 19.1781 2.68435 19.0806C2.91747 18.9831 3.12891 18.8403 3.3064 18.6605L9.6394 12.3315L15.9724 18.6645C16.3337 19.0226 16.8217 19.2235 17.3304 19.2235C17.8391 19.2235 18.3271 19.0226 18.6884 18.6645C18.8682 18.487 19.011 18.2756 19.1085 18.0425C19.2059 17.8093 19.2561 17.5592 19.2561 17.3065C19.2561 17.0538 19.2059 16.8037 19.1085 16.5706C19.011 16.3374 18.8682 16.126 18.6884 15.9485Z" fill="black"/>
        </svg>
    )
}

export const IconConfirmSuccess = () => {
    return (
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.6506 40.839L13.3359 29.5217L17.1066 25.751L24.6506 33.2924L39.7333 18.207L43.5066 21.9804L24.6506 40.8337V40.839Z" fill="#7849B8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 29.3333C0 13.1333 13.1333 0 29.3333 0C45.5333 0 58.6667 13.1333 58.6667 29.3333C58.6667 45.5333 45.5333 58.6667 29.3333 58.6667C13.1333 58.6667 0 45.5333 0 29.3333ZM29.3333 53.3333C26.1816 53.3333 23.0607 52.7126 20.1489 51.5064C17.2371 50.3003 14.5914 48.5325 12.3628 46.3039C10.1342 44.0753 8.36634 41.4296 7.16022 38.5177C5.95411 35.6059 5.33333 32.4851 5.33333 29.3333C5.33333 26.1816 5.95411 23.0607 7.16022 20.1489C8.36634 17.2371 10.1342 14.5914 12.3628 12.3628C14.5914 10.1342 17.2371 8.36634 20.1489 7.16022C23.0607 5.95411 26.1816 5.33333 29.3333 5.33333C35.6985 5.33333 41.803 7.8619 46.3039 12.3628C50.8048 16.8636 53.3333 22.9681 53.3333 29.3333C53.3333 35.6985 50.8048 41.803 46.3039 46.3039C41.803 50.8048 35.6985 53.3333 29.3333 53.3333Z" fill="#7849B8"/>
        </svg>
    )
}

export const IconUnelected = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9"/>
        </svg>
    )
}

export const IconSelected = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9"/>
        </svg>
    )
}

export const IconUpload = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z" fill="black"/>
        </svg>
    )
}

export const Star = () => {
    return(
        <svg width="48" height="48" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31 0L38.1844 22.1115H61.4338L42.6247 35.7771L49.8091 57.8885L31 44.2229L12.1909 57.8885L19.3753 35.7771L0.566191 22.1115H23.8156L31 0Z" fill="#D9D9D9"/>
        </svg>
    )
}

export const SelectedStar = () => {
    return(
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 643 612" fill="none">
        <g filter="url(#filter0_d_5345_9496)">
        <path d="M321.5 0L396.375 230.443L638.677 230.443L442.651 372.864L517.526 603.307L321.5 460.886L125.474 603.307L200.349 372.864L4.32266 230.443L246.625 230.443L321.5 0Z" fill="#FFDF35" fill-opacity="0.7" shape-rendering="crispEdges"/>
        </g>
        <defs>
        <filter id="filter0_d_5345_9496" x="0.320312" y="0" width="642.359" height="611.309" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5345_9496"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5345_9496" result="shape"/>
        </filter>
        </defs>
    </svg>
    )
}