import useTitle from "../../hooks/useTitle";
import AuthRegisterForm from "../components/AuthRegisterForm";
import PrincipalContactoResumen from "../../homeout/principal/components/PrincipalContactoResumen";
import AuthRegisterImagenes from "../components/AuthRegisterImagenes";
import "../styles/authActivar.css";

const AuthRegister = () => {
  useTitle({ title: "Registro" });

  return (
    <>
      <main className="login__container">
        <div className="login__container-datos">
          <AuthRegisterForm />
        </div>

        <AuthRegisterImagenes />
      </main>
      <PrincipalContactoResumen />
    </>
  );
};

export default AuthRegister;
