import { useState } from 'react';
import Portal from '../../../helpers/Portal';
import PrincipalContactoContactos from './PrincipalContactoContactos';
import PrincipalLogos from './PrincipalLogos';
import { useLocation } from 'react-router-dom';
import "../../../../../src/modules/homeout/principal/styles/homeOutContacto.css"

import { ProteccionDeDatos } from '../../../helpers/ProteccionDeDatos';
import { terminosycondiciones } from '../../../helpers/terminoscondiciones';
import { PoliticasDeTratamiento } from '../../../helpers/PoliticasDeTratamiento';

const PrincipalContactoResumen = () => {
    const [terminos, setTerminos] = useState(false);
    const [cookies, setCookies] = useState(false);
    const [politicas, setPoliticas] = useState(false);
    const [mostrarLegales, setMostrarLegales] = useState(false);

    const location = useLocation();

    // Verificar si el usuario NO está en ecosistema y si NO está autenticado
    const shouldRenderFooter = !location.pathname.includes('/ecosistema');

    if (!shouldRenderFooter) return null; // Si no se cumple, no renderiza nada

    return(
        <>
            {
                terminos && <Portal setTerminos={setTerminos} terminos={terminosycondiciones} />
            }
            {
                politicas && <Portal setTerminos={setPoliticas} terminos={PoliticasDeTratamiento} />
            }
            {
                cookies && <Portal setTerminos={setCookies} terminos={ProteccionDeDatos} />
            }
            <div 
            className='contacto__resumen'
            >
                <div style={{ paddingTop: "30px" }}>
                    <PrincipalLogos direccion='centro'/>
                </div>
                <div style={{ padding: '2rem' }}>
                    {/* <PrincipalLogos direccion='izquierda' /> */}
                </div>
                <hr style={{margin: 0}} />

                <PrincipalContactoContactos />

                <div className="contacto__conocenos resumen">
                    <div className="contacto__conocenos-texto resumen">
                        <div className="conocenos__texto-conocenos">
                            {/*<div className='conocenos__texto-group'>
                                <p className='conocernos__texto-item claro'>Conócenos</p>
                                <i className="fa-solid fa-plus mobile"></i>
                            </div>
                            <p className='conocernos__texto-item desktop'><a href='https://cardif.com.mx/' target="_blank" rel="noreferrer">Nosotros</a></p>
                            <p className='conocernos__texto-item desktop'>Productos</p>*/}
                        </div>
                        <div className="conocenos__texto-legales">
                            <div className='conocenos__texto-group'>
                                <p className='conocernos__texto-item claro'>Legales</p>
                                {
                                    !mostrarLegales ?
                                        <i className="fa-solid fa-plus mobile" onClick={() => setMostrarLegales(!mostrarLegales)}></i> :
                                        <i className="fa-solid fa-minus mobile" onClick={() => setMostrarLegales(!mostrarLegales)}></i>
                                }
                            </div>
                            {
                            mostrarLegales &&
                            <div className='mobile'>
                                <p className='conocernos__texto-item mobile' style={{ fontFamily: 'Scotia-Headline' }} onClick={() => { setTerminos(true) }} >Términos y condiciones</p>
                                <p className='conocernos__texto-item mobile' style={{ fontFamily: 'Scotia-Headline' }} onClick={() => { setPoliticas(true) }} > Aviso de privacidad </p>
                                <p className='conocernos__texto-item mobile' style={{ fontFamily: 'Scotia-Headline' }} onClick={() => { setCookies(true) }} >Política de cookies</p>
                            </div>
                            }
                        </div>
                    </div>
                    <div style={{display: "flex", fontSize: "1.4rem", fontFamily: "Scotia-bold"}}>
                        <p className='conocernos__texto-item desktop' onClick={() => { setTerminos(true) }}>Términos y condiciones</p>
                        <div className='divisor'></div>
                        <p className='conocernos__texto-item desktop' onClick={() => { setCookies(true) }}>Política de cookies</p>
                        <div className='divisor'></div>
                        <p className='conocernos__texto-item desktop' onClick={() => { setPoliticas(true) }}>Aviso de privacidad</p>
                        </div>

                    <div className="contacto__conocenos-link">
                        {/*<div className='contacto__link-resumen'>
                            <p className='mobile'>Materiales para descargar</p>
                            <p>Accesibilidad</p>
                            <p className='desktop'>Mapa de sitio</p>
                        </div>*/}
                    </div>
                </div>
            </div>
        </>
    )
}


export default PrincipalContactoResumen