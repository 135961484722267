import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import HomeInScreen from '../modules/homein/principal/screens/HomeInScreen'
import RouterEmprendimiento from './emprendimiento/RouterEmprendimiento'
import '../modules/homein/styles/homein.css'
import '../modules/hooks/ecosistemas.css'
import '../modules/helpers/portal.css'
import RouterEmpleabilidad from './empleabilidad/RouterEmpleabilidad'
import HomeConfiguracion from '../modules/homein/principal/screens/HomeConfiguracion'
import useActive from '../modules/hooks/useActive'
import PrincipalContactoResumen from '../modules/homeout/principal/components/PrincipalContactoResumen'
import ScrollToTop from './ScrolltoTop'
import RouterProteccionFinanciera from './proteccion-financiera/RouterProteccionFinanciera'
import RouterVidaPlus from './vidaplus/RouterVidaPlus'
import RouterProteccionFinancieraElemental from './proteccion-financiera-elemental/RouterProteccionFinancieraElemental'
import RouterCoursera from './coursera/RouterCoursera'
import RouterSalud from './salud/RouterSalud'
import RouterVida from './vida/RouterVida'
import RouterValorFactura from './valor-factura/RouterValorFactura'
import PageNotFound from '../modules/404/PageNotFound'
import HomeAgendamientos from '../modules/homein/principal/screens/HomeAgendamientos'
import RouterCuidados from './cuidados/RouterCuidados'
import { useContext, useEffect, useState } from 'react'
import { CantidadIntentosCoursera } from '../services/cursera'
import { getToken } from '../services/logs_proteccion'
import ModalEncuestaCoursera from '../modules/hooks/components/ModalEncuestaCoursera'
import ModalEncuestaCourseraVida from '../modules/hooks/components/ModalEncuestaCourseraVida'
import AuthContext from '../context/auth/authContext'


const RouterHomeIn = () => {
  // 1minuto = 60000
  // 5minuto = 300000
  // 30min = 1800000
  const userActive = useActive(300000);
  const location = useLocation();

  const { globalModulos } = useContext(AuthContext);
  const [mostrarModalEncuesta, setMostrarModalEncuesta] = useState(false);
  const [mostrarModalEncuestaVida, setMostrarModalEncuestaVida] = useState(false);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.loggedIn === true) {
        let cantidadIntentos = 0;

        if(globalModulos.modEmpleabilidad || globalModulos.modEmprendimiento || globalModulos.modVida || globalModulos.modPFinanciera || globalModulos.modVidaPlus || globalModulos.modCoursera) {

          CantidadIntentosCoursera(getToken()).then((resp) => {
            cantidadIntentos = resp.data;

            if (cantidadIntentos <= 2) {
              setTimeout(() => {
                if (window.location.href.includes("/ecosistema/vida")) {
                  setMostrarModalEncuestaVida(true);
                }
                else {
                  setMostrarModalEncuesta(true);
                }
              }, 15000)
            }
          });
        }
      }
    }
  }, [])

  return (
    <>      
      <ScrollToTop />
        <Routes>
          <Route path='/emprendimiento/*' element={<RouterEmprendimiento />} />
          <Route path='/empleabilidad/*' element={<RouterEmpleabilidad />} />
          <Route path='/proteccion-financiera/*' element={<RouterProteccionFinanciera />} />
          <Route path='/vidaplus/*' element={<RouterVidaPlus />} />
          <Route path='/mi-salud-financiera/*' element={<RouterProteccionFinancieraElemental />} />
          <Route path='/coursera' element={<RouterCoursera />} />
          <Route path='/salud/*' element={<RouterSalud />} />
          <Route path='/vida/*' element={<RouterVida />} />
          <Route path='/cuidados/*' element={<RouterCuidados />} />
          <Route path='/valor-factura/*' element={<RouterValorFactura />} />
          <Route path="/configuracion" element={<HomeConfiguracion />} />
          <Route path="/mis-agendamientos" element={<HomeAgendamientos />} />
          <Route path="/" element={<HomeInScreen />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
      </Routes>

      <PrincipalContactoResumen />

      <ModalEncuestaCoursera
        show={mostrarModalEncuesta}
        close={setMostrarModalEncuesta}
      />

      <ModalEncuestaCourseraVida
        show={mostrarModalEncuestaVida}
        close={setMostrarModalEncuestaVida}
      />

    </>
  );
};

export default RouterHomeIn;
