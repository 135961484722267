import { useState } from "react";
import { useEffect } from "react";
import Countdown from "react-countdown";

export default function Timer({ mill, end, status, handleSetTimer, pagina = "" }) {

    let dateTime = Date.now() + mill;

    const [timeCodeIndex, setTimeCodeIndex] = useState(false);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        let milisegunSegundos = seconds * 1000;
        let milisegunMinutos = minutes * 60000;
        let tiempoFinal = milisegunSegundos + milisegunMinutos;
        handleSetTimer(tiempoFinal);
        if (completed && pagina !== "prueba") {
            return (
                <span className='timer-span tx-primary tx-bold'>
                    <span className='timer'>
                        { (timeCodeIndex) && end(false) }
                    </span>
                </span>
              );
        } else {
          return (
            pagina === "prueba" ?
            <span>{`${(hours.length === 1 || hours === 0) ? `0${hours}` : hours}:${String(minutes).length === 1 ? `0${minutes}` : minutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}`}</span>
            : 
            <span style={{display: status ? 'none' : 'block'}} className='timer-span tx-primary tx-bold'>
                {`${String(minutes).length === 1 ? `0${minutes}` : minutes}:${String(seconds).length === 1 ? `0${seconds}` : seconds}`} Seg.
            </span>
          );
        }
    };

    useEffect(()=>{
        setTimeCodeIndex(!timeCodeIndex);
    },[status]);

    return (
        <Countdown date={dateTime} renderer={renderer} key={timeCodeIndex} />
    )
}