import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../../context/auth/authContext'
import ModalSaltoCoursera from '../../../hooks/components/ModalSaltoCoursera'

const HomeInUserEcosistemas = ({ ecoDetalle, index, color, ecoPadre }) => {

    const { globalDataUser, globalBeneficiariosSalud, globalBeneficiariosSaludModal, globalActualizarBeneficiariosSaludModal, globalCurpVida, globalActualizarVidaModalCurp, globalCurpVidaModal, globalInactivaSesion } = useContext(AuthContext)

    //const plan1 = [1, 3, 5, 7, 9, 11]
    const plan2 = [2, 4]
    const plan3 = [6, 8, 10, 12]
    const idPlanCuidadoUsuario = parseInt(globalDataUser.globalPlanCardifId?.substring(2))
    const [showModalSaltoCoursera, setShowModalSaltoCoursera] = useState(false);

    const navigate = useNavigate()

    const prevNavigate = (ecoDetalle) => {
        if (ecoDetalle.id === 38 || ecoDetalle.id === 7 || ecoDetalle.id === 12) {
            setShowModalSaltoCoursera(true)
            return
        }
        let ecosSinSalto = [9,61,63,64,66,67,71,73,75,76,77,78,79];
        if(!ecosSinSalto.includes(ecoDetalle.id)){ // hace el salto si son diferentes a estas card
            let ecosValidacion = [60,62,65];
            if(ecosValidacion.includes(ecoDetalle.id)){
                if(ecoDetalle.id === 60 || ecoDetalle.id === 62){ // Se validan los ecos de Salud
                    if(!globalBeneficiariosSalud){
                        globalActualizarBeneficiariosSaludModal(globalBeneficiariosSaludModal+1); // Se seta para mostrar modal
                        return;
                    }
                    if(ecoDetalle.id === 62){
                        return;
                    }
                }
                if(ecoDetalle.id === 65){
                    if(!globalCurpVida){
                        globalActualizarVidaModalCurp(globalCurpVidaModal+1);
                        return
                    }
                }
            }
            navigate(ecoDetalle.linkIn);
        }
    }


    return (
        <>
            {showModalSaltoCoursera && (
                <ModalSaltoCoursera
                    show={showModalSaltoCoursera}
                    setShow={setShowModalSaltoCoursera}
                    color={color}
                    globalDataUser={globalDataUser}
                    globalInactivaSesion={globalInactivaSesion}
                    ecoPadre={ecoPadre}
                />
            )}
            { ((ecoDetalle.id === 76 || ecoDetalle.id === 77) && !plan2.includes(idPlanCuidadoUsuario)) ||
              ((ecoDetalle.id === 78 || ecoDetalle.id === 79) && !plan3.includes(idPlanCuidadoUsuario)) ? <></> :
                <div onClick={() => prevNavigate(ecoDetalle)} >        
                    <div className="user_card-eco" >
                        <img className='user_card-img' src={ecoDetalle.ico} alt="imagen eco icono"/>
                        <h4 className={`user_card-text`}>{ecoDetalle.shorttitle}</h4>
                        {/* <h4 className={`user_card-text`}>{index + 1}. {ecoDetalle.title}</h4> */}
                    </div>
                </div>
            }
        </>

    )
}

export default HomeInUserEcosistemas