import React from 'react'
import useCards from '../../../hooks/useCards'
import useOutEcosistema from '../../../hooks/useOutEcosistema'
import useTitle from '../../../hooks/useTitle'
import HomeOutActivaTuCuenta from '../../ecosistemas/HomeOutActivaTuCuenta'
import PrincipalContactoResumen from '../../principal/components/PrincipalContactoResumen'

const HomeOutEmpleabilidad = () => {
  useTitle({ title: 'Empleabilidad | BNP Paribas Cardif' })
  const { ecosistemaPresentacionOut } = useOutEcosistema()
  const { ecosistemaCards } = useCards()

  return (
    <>
      {ecosistemaPresentacionOut(3)}

      {ecosistemaCards(3)}

      <HomeOutActivaTuCuenta />
      <div style={{ backgroundColor:'red !important' }}>
        <PrincipalContactoResumen /> {/* footer - homeOut main */}
      </div>

    </>
  )
}

export default HomeOutEmpleabilidad