import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import AuthContext from "../../../context/auth/authContext";
import { postLogin, getSession } from "../../../services/auth";
import ReCAPTCHA from "react-google-recaptcha";
import {
  encodeBase64,
  encryptDecrypt,
  getCoberturas,
  getIdUser,
  isactive,
  getCoberturasGlobal
} from "../../helpers/validations";
import { CLI_PUB, COD_RECAPTCHA_V3 } from "../../../enviroments/enviroment";
import { addLogs } from "../../../services/logs_proteccion";
import * as CryptoJS from "crypto-js";
import sha256 from "sha256";
import { useNavigate } from "react-router-dom";
import { refreshTokenSession } from "../../helpers/sessions";

const AuthLoginFormIdentification = ({ mensaje, setMensaje, setSpinner }) => {
  const { globalIniciarSesion, globalEcosistemas } =
    useContext(AuthContext);

  const [form, setForm] = useState({
    usuario: "",
    password: "",
    tokenCaptcha: "",
  });

  const [oculto, setOculto] = useState(true);
  const [mensajeErrorCorreo, setMensajeErrorCorreo] = useState('')
  const [mensajeErrorPass, setMensajeErrorPass] = useState('')

  // RECAPTCHA
  const recaptchaRef = useRef();

  let navigate = useNavigate();

  const gotoOlvideContrasena = () => {
    navigate("/password");
  };

  const filtraEcosistemasActivos = () => {
    let data = globalEcosistemas.filter((e) => e.active === true);
    let modulosActivos = [];

    for (let i = 0; i < data.length; i++) {
      modulosActivos[i] = data[i].name;
    }

    return modulosActivos;
  };

  const cambiarOculto = () => {
    setOculto(!oculto);
  };

  const handleChange = (e) => {
    document.getElementById(e.target.id).style.borderColor = "var(--borde)"
    document.getElementById(e.target.id).style.color = "var(--bs-offcanvas-color)"
    if (e.target.id === "usuario")
        setMensajeErrorCorreo("")
    if (e.target.id === "password")
        setMensajeErrorPass("")
    setMensaje("")
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const validaruta = (perfilesActivos) => {
    if (isactive()) {
      //let cobertura = getCoberturas()
      let cobertura = globalEcosistemas.filter((e) => e.active === true);
      for (let i = 0; i < cobertura.length; i++) {
        for (let x = 0; x < perfilesActivos.length; x++) {
          if (cobertura[i].name === perfilesActivos[x]) {
            navigate("/ecosistema" + cobertura[i].rutaexterna, {
              state: {
                loggedIn: true,
              },
            });
            return;
          }
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    window.dataLayer.push({
      event: "SBK_GR_INICIASESION_BTN_INICIARSESION",
      action: "BTN_INICIARSESION",
    });

    let errorCampoVacio = false
    if (form.usuario.trim() === "") {
      document.getElementById("usuario").style.borderColor = 'var(--rojo)'
      setMensajeErrorCorreo("Este dato es necesario")
      errorCampoVacio = true
    }
    if (form.password.trim() === "") {
      document.getElementById("password").style.borderColor = 'var(--rojo)'
      setMensajeErrorPass("Este dato es necesario")
      errorCampoVacio = true
    }

    if (errorCampoVacio) return

    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!form.usuario.trim().match(pattern)) {
        document.getElementById("usuario").style.borderColor = 'var(--rojo)'
        document.getElementById("usuario").style.color = 'var(--rojo)'
        setMensaje('Revisa que contenga @ y los puntos necesarios.')
        return
      }

    setMensajeErrorCorreo("")
    setMensajeErrorPass("")
    setMensaje("")

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (!token) {
      document.getElementById("usuario").style.borderColor = 'var(--rojo)'
      document.getElementById("usuario").style.color = 'var(--rojo)'
      setMensaje("Captcha inválido, actualizar web");
      return;
    }

    document.getElementById("usuario").style.borderColor = "var(--borde)"
    document.getElementById("usuario").style.color = "var(--bs-offcanvas-color)"
    document.getElementById("password").style.borderColor = "var(--borde)"
    document.getElementById("password").style.color = "var(--bs-offcanvas-color)"

    setSpinner(true);
    let objUsuario = {
      usuario: encodeBase64(form.usuario),
      password: encodeBase64(
        encryptDecrypt(
          sha256(form.password),
          token.substring(token.length - 10, token.length)
        )
      ),
      sitioPassword: "9B9CB6631B27AA1DF47DB351B497004810015B09",
      sitioUsuario: "CARDIF",
      deviceInfo: "",
      tokenCaptcha: token,
    };

    postLogin(objUsuario).then((rpta) => {
      if (rpta.codigo === 0) {
        sessionStorage.setItem(
          "token",
          CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB)
        );
        getSession(rpta.data.token).then((data) => {
          let res = { data }.data;
          let perfilesActivos = res.perfiles.$values;
          let modulosActivos = filtraEcosistemasActivos();
          let sumaModuloActivo = 0;
          for (let i = 0; i < modulosActivos.length; i++) {
            for (let x = 0; x < perfilesActivos.length; x++) {
              if (modulosActivos[i] === perfilesActivos[x]) {
                sumaModuloActivo = sumaModuloActivo + 1;
              }
            }
          }
          if (sumaModuloActivo === 0) {
            document.getElementById("usuario").style.borderColor = 'var(--rojo)'
            document.getElementById("usuario").style.color = 'var(--rojo)'
            setMensaje("Tu seguro aún no está activo. Podrás activar tu cuenta próximamente");
            setSpinner(false);
            return;
          }
          sessionStorage.setItem(
            "user",
            CryptoJS.AES.encrypt(JSON.stringify(res), CLI_PUB)
          );
          setSpinner(false);

          addLogs(
            "Iniciar Sesión",
            "Inicio de sesión exitoso",
            JSON.stringify(objUsuario)
          );

          let idUser = getIdUser();
          globalIniciarSesion({
            globalDataUser: {
              globalUsuId: idUser,
              globalUsuName: res.nombres,
              globalUsuSurnameMat: res.apellidoMaterno,
              globalUsuSurname: res.apellidoPaterno,
              globalUsuCellphone: res.numeroCelular,
              globalUsuEmail: res.correoElectronico,
              globalUsuTipoDoc: res.dicTipoDocumento,
              globalDocIdentidad: res.identificacion,
              globalUsuRole: "",
              globalInvitado: res.nroinvitado > 0,
              globalNroInvitados: res.nroinvitado,
              globalPlanCardifId: res.idPlanCardif,
            },
            globalModulos: getCoberturasGlobal(getCoberturas()),
            globalToken: CryptoJS.AES.encrypt(rpta.data.token, CLI_PUB),
            globalTokenUser: CryptoJS.AES.encrypt(
              JSON.stringify(res),
              CLI_PUB
            ),
            globalAutenticado: true,
            globalCargando: false,
            globalStatusEncuesta: res.encuestaNeo === 1,
          });
          setMensaje("");

          //Verifica check en "Recuerdame" y guarda el correo del usuario en localStorage si se marco
          if (document.getElementById("recuerdame").checked) {
            localStorage.setItem("correoUsuario", form.usuario)
          }
          else {
            localStorage.removeItem("correoUsuario")
          }

          refreshTokenSession();
          validaruta(perfilesActivos);
        });
      } else {
        //setMensaje(rpta.mensaje)
        if (rpta.mensaje === 'Contraseña incorrecta.') {
          document.getElementById("password").style.borderColor = 'var(--rojo)'
          document.getElementById("password").style.color = 'var(--rojo)'
        }
        else {
            document.getElementById("usuario").style.borderColor = 'var(--rojo)'
            document.getElementById("usuario").style.color = 'var(--rojo)'
        }
        setMensaje(rpta.mensaje);
        setSpinner(false);
      }
    }).catch((err) => {
      document.getElementById("usuario").style.borderColor = 'var(--rojo)'
      document.getElementById("usuario").style.color = 'var(--rojo)'
      setMensaje("Hubo un problema en la autenticación.");
      setSpinner(false);
    });
  };

  useEffect(() => {
    if (localStorage.getItem("correoUsuario") !== null) {
      form.usuario = localStorage.getItem("correoUsuario")
      document.getElementById("usuario").value = form.usuario
      document.getElementById("recuerdame").checked = true
    }
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="auth__login-label">Correo electrónico</div>
        <div className="auth__login-group">
          <input
            type="text"
            className="auth__login-docompleto"
            placeholder="Ingresa tu correo"
            name="usuario"
            id="usuario"
            value={form.usuario}
            onChange={handleChange}
          />
        </div>
        {
            mensajeErrorCorreo &&
            <div className='texto__error'>
                <p>{mensajeErrorCorreo}</p>
            </div>
        }
        {
          (mensaje !== "" && mensaje !== "Contraseña incorrecta.") &&
          <div className='texto__error'>
            <p>{mensaje}</p>
          </div>
        }

        <div className="auth__login-label">Contraseña</div>
        <div className="auth__login-group">
          <div className="auth__login-pass">
            <input
              type={`${oculto ? "password" : "text"}`}
              className="auth__pass"
              placeholder="Ingresa tu contraseña"
              name="password"
              id="password"
              value={form.password}
              onChange={handleChange}
              autoComplete="off"
            />
            {oculto ? (
              <i
                onClick={cambiarOculto}
                className="fa-solid fa-eye-slash pass__icon"
              ></i>
            ) : (
              <i
                onClick={cambiarOculto}
                className="fa-solid fa-eye pass__icon"
              ></i>
            )}
          </div>
        </div>
        {
          mensajeErrorPass &&
          <div className='texto__error'>
            <p>{mensajeErrorPass}</p>
          </div>
        }
        {
          mensaje === "Contraseña incorrecta." &&
          <div className='texto__error'>
            <p>{mensaje}</p>
          </div>
        }

        <div className="auth__rutas">
          <p className="auth__logueo-text custom_checkbox">
            <label>
              <input type="checkbox" id="recuerdame" />
              Recuérdame
            </label>
          </p>
          <p className="auth__logueo-btn" onClick={gotoOlvideContrasena}>
            ¿Olvidaste tu contraseña?
          </p>
        </div>

        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={COD_RECAPTCHA_V3}
          />
          <input type="submit" className="btn__login" value="Iniciar sesión" />
        </div>
      </form>
    </>
  );
};

export default AuthLoginFormIdentification;
