import '../styles/homeOutOfertas.css'
import LazyPrincipalOfertasCards from './LazyPrincipalOfertasCards'

const PrincipalOfertas = () => {
    return (
        <div className='homeout__ofertas'>
            <h2>¿Qué te ofrecemos?</h2>
            <p>Al contratar cualquiera de nuestros seguros Cardif, podrás acceder a:</p>
            <LazyPrincipalOfertasCards />
        </div>
    )
}

export default PrincipalOfertas