import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import HeaderMenu from "../components/HeaderMenu";
import useLogos from "../../hooks/useLogos";
import useAuth from "../../hooks/useAuth";
import HeaderHamburger from "../components/HeaderHamburger";
import HeaderUserMobile from "../components/HeaderUserMobile";
import "../../../styles/index.css";
import "../styles/header.css";
import { addLogs } from "../../../services/logs_proteccion";
import { IconDisclaimerAlert, IconDisclaimerClose } from "../../hooks/components/Icons";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const { dosLogosHorizontal } = useLogos();
  const { globalAutenticado } = useAuth();
  const [hamburger, setHamburger] = useState(false);
  const [user, setUser] = useState(false);
  const [direcionInicio, setDireccionInicio] = useState("/");
  const [showAlert, setShowAlert] = useState(true)

  const mobile = useMediaQuery({maxWidth: 768})

  const { pathname } = useLocation();
  const obtieneInicio = () => {
    let dir = pathname.split("/");
    if (
      dir[2] === undefined ||
      dir[2] === "configuracion" ||
      dir[2] === "invitados" ||
      dir[2] === "mis-agendamientos"
    ) {
      //setDireccionInicio(`/${dir[1]}`)
    } else {
      setDireccionInicio(`/${dir[1]}/${dir[2]}`);
    }
  };

  useEffect(() => {
    obtieneInicio();
  }, [pathname]);

  return (
    <>
      {pathname.includes("404") ? (
        <></>
      ) : (
        <>
          <div className="home__header" style={(mobile && pathname.includes('iniciasesion')) ? {justifyContent: "start"} : {justifyContent: "space-between"}}>
            {/* <div
              onClick={() => setHamburger(!hamburger)}
              className="home__header-hamburger mobile"
            >
              <img
                src="/assets/img/homeout/img_hamburger.png"
                alt="imagen hamburguer"
              />
            </div> */}

            {/* LOGOS DE HEADER */}
            <div className="home__logo">
              <NavLink
                to={`${globalAutenticado ? direcionInicio : "/"}`}
                className="header__text"
                onClick={() => addLogs('Inicio', 'Usuario hace clic en logo Vivetuseguro para volver al inicio', '')}
              >
                {dosLogosHorizontal}
              </NavLink>
            </div>

            {/* ZONA DE BOTONES */}
            { !window.location.pathname.includes('registroexitoso') &&
              <HeaderMenu globalAutenticado={globalAutenticado} />
            }
          </div>
          {hamburger && (
            <div className={`hamburger__container`}>
              <div className="hamburger__text">
                <HeaderHamburger hamburger={hamburger} setHamburger={setHamburger} setUser={setUser} />
              </div>
            </div>
          )}
          {user && (
            <div className={`hamburger__container`}>
              <div className="hamburger__text">
                <HeaderUserMobile
                  setHamburger={setHamburger}
                  setUser={setUser}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="alert__wrapper">
        <div className={`alert ${ !showAlert && 'alert__closer'}`} >
          <div className="alert__icon">
            <IconDisclaimerAlert />
          </div>
            <p className="alert__text">Para mejorar tu experiencia de navegación, por favor asegúrate 
              de desbloquear las ventanas emergentes.</p>
          <div className="alert__close" onClick={() => {setShowAlert(false)}}>
            <IconDisclaimerClose />
          </div>
          </div>
        </div>
    </>
  );
};

export default Header;
