import React from "react";
import useCards from "../../../hooks/useCards";
import useOutEcosistema from "../../../hooks/useOutEcosistema";
import useTitle from "../../../hooks/useTitle";
import HomeOutActivaTuCuenta from "../../ecosistemas/HomeOutActivaTuCuenta";
import PrincipalContactoResumen from '../../principal/components/PrincipalContactoResumen'

const HomeOutValorFactura = () => {
  useTitle({ title: "Valor Factura | BNP Paribas Cadif" });
  const { ecosistemaPresentacionOut } = useOutEcosistema();
  const { ecosistemaCards } = useCards();

  return (
    <>
      {ecosistemaPresentacionOut(15)}

      {ecosistemaCards(15)}

      <HomeOutActivaTuCuenta />
      <div style={{ background: "var(--bg_claro)" }}>

        <PrincipalContactoResumen />
      </div>
    </>
  );
};

export default HomeOutValorFactura;
