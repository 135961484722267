import React from 'react'
import useCards from '../../../hooks/useCards'
import useOutEcosistema from '../../../hooks/useOutEcosistema'
import useTitle from '../../../hooks/useTitle'
import HomeOutActivaTuCuenta from '../../ecosistemas/HomeOutActivaTuCuenta'
import PrincipalContactoResumen from '../../principal/components/PrincipalContactoResumen'

const HomeOutProteccionFinancieraElemental = () => {

  useTitle({ title: 'Mi Salud Financiera | BNP Paribas Cardif' })
  const { ecosistemaPresentacionOut } = useOutEcosistema()
  const { ecosistemaCards } = useCards()

  return (
    <>
      {ecosistemaPresentacionOut(10)}

      {ecosistemaCards(10)}

      <HomeOutActivaTuCuenta />
      <div style={{ backgroundColor:'red !important' }}>
        <PrincipalContactoResumen /> {/* footer - homeOut main */}
      </div>

    </>
  )
}

export default HomeOutProteccionFinancieraElemental