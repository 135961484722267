import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthContext from "../../../context/auth/authContext";
import { getCoursera } from "../../../services/cursera";
import { addLogs } from "../../../services/logs_proteccion";
import { programasCoursera } from "../../helpers/data";
import { getToken } from "../../helpers/validations";
import { getMedismart } from "../../../services/salud";
import { getSchedules, getSchedulesCow } from "../../../services/agenda";
import ModalVerCita from "../../hooks/components/ModalVerCita";
import { IconArrowCard } from "../../hooks/components/Icons";
import ModalSaltoCoursera from "../../hooks/components/ModalSaltoCoursera";

const InEcosistemasCards = ({
  ecoDetalle,
  index,
  ecoPadre,
  setTieneCitaAgendada,
}) => {
  const {
    globalDataUser,
    globalInactivaSesion,
    globalEcosistemas,
    globalBeneficiariosSalud,
    globalBeneficiariosSaludModal,
    globalBeneficiariosSaludModalEnCasa,
    globalCurpVida,
    globalCurpVidaModal,
    globalActualizarBeneficiariosSaludModal,
    globalActualizarBeneficiariosSaludModalEnCasa,
    globalActualizarVidaModalCurp,
  } = useContext(AuthContext);

  const ecosSinSalto = [9,61, 63, 67, 71, 73, 75, 76, 77, 78, 79];
  const idPlanCuidadoUsuario = parseInt(
    globalDataUser.globalPlanCardifId?.substring(2)
  );

  const plan2 = [2, 4];
  const plan3 = [6, 8, 10, 12];

  const [linkCoursera, setLinkCoursera] = useState(null);
  const [refreshCoursera, setRefreshCoursera] = useState(true);
  const [mostrarMensajeCita, setMostrarMensajeCita] = useState(false);
  const [showModalCita, setShowModalCita] = useState(false);
  const [arrowIngreso, setArrowIngreso] = useState(<IconArrowCard id="arrowCard" color={`var(--${ecoPadre.color} )`} />);
  const [showModalSaltoCoursera, setShowModalSaltoCoursera] = useState(false);

  const idPlanesIndividuales = [
    "2960",
    "2961",
    "2962",
    "2963",
    "2964",
    "2970",
    "2971",
    "2972",
    "2973",
    "2974",
  ];

  const [objAgenda, setObjAgenda] = useState({
    servicio: "",
    fechaAgenda: "",
    hora: "",
    medio: "",
    telefono: "",
    urlVideollamada: "",
  });

  const callGTM = () => {
    window.dataLayer.push({
      event: `SBK_${ecoDetalle.detail_eco}_${ecoDetalle.detail_service}_BTN_${ecoDetalle.detail_action}`,
      action: `BTN_${ecoDetalle.detail_action}`,
    });
  };

  const navigate = useNavigate();
  const gotoEcosistemaModulo = () => {
    callGTM();
    if (ecoDetalle.shorttitle === "Check-up anual") {
      addLogs(
        ecoPadre.name,
        'Clic en "Ingresar" (ingreso a Check-up anual)',
        ecoPadre.name
      );
    } else {
      addLogs(
        ecoDetalle.shorttitle === "Verificación vehicular" ||
          ecoDetalle.shorttitle === "Consulta de multas"
          ? ecoPadre.name
          : ecoDetalle.shorttitle,
        ecoPadre.name === "Cuidados" || ecoPadre.name === "Valor Factura"
          ? `Ingreso ${ecoDetalle.shorttitle}`
          : `Navega de ${ecoPadre.name} a ${ecoDetalle.shorttitle}`,
        ecoPadre.name
      );
    }

    navigate(ecoDetalle.linkIn);
  };

  const gotoCoursera = async () => {
    callGTM();
    addLogs("Coursera", "Usuario entra a Coursera en " + ecoPadre.name);
    setRefreshCoursera(true);
  };

  const saltoMedismart = async (idBeneficiario, buttonClicked = false) => {
    Swal.fire({
      icon: "info",
      title: "Cargando...",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    let data = await getMedismart(getToken(), idBeneficiario, "vida");
    if (!data?.mensaje && data?.codigo !== 1) {
      if (buttonClicked) {
        addLogs("Atención médica inmediata", "Salto exitoso a Medismart", "");
      }
      setLinkCoursera(data);
      let linkSaltoMedismart = data?.Message ? null : data
      setLinkCoursera(linkSaltoMedismart);
      //window.open(data, "_blank");
      Swal.close();
    } else {
      if (data?.codigo === 1) {
        Swal.fire({
          icon: "info",
          title: "Medismart",
          text: data.mensaje,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error en salto a medismart",
        });
      }
    }
  };

  const preGoToEcosistemaModulo = (e) => {
    if (e.target.dataset.id === "64") {
      // Coursera
      gotoCoursera();
      return;
    }
    if (e.target.dataset.eco === "Salud") {
      // Se valida para el ecosistema Salud
      if (
        globalBeneficiariosSalud ||
        idPlanesIndividuales.includes(globalDataUser.globalPlanCardifId)
      ) {
        if (e.target.dataset.id === "62") {
          callGTM();
          globalActualizarBeneficiariosSaludModalEnCasa(
            globalBeneficiariosSaludModalEnCasa + 1
          );
          return;
        }
        gotoEcosistemaModulo();
      } else {
        // se valida para mostrar modal en el ecosistema
        globalActualizarBeneficiariosSaludModal(
          globalBeneficiariosSaludModal + 1
        ); // Se seta para mostrar modal
      }
    } else if (e.target.dataset.eco === "Vida") {
      // Se valida para el ecosistema Vida
      if (!globalCurpVida) {
        globalActualizarVidaModalCurp(globalCurpVidaModal + 1);
        return;
      }
      if (e.target.dataset.id === "66") {
        console.log("Ingreso a data set 66");
        addLogs("Atención médica inmediata", "Ingreso a la card", "");
        // Atención médica inmediata
        // Salto integración con Medisanitas
        saltoMedismart(0, true);
        return;
      }
      gotoEcosistemaModulo();
    }
  };

  const callNumber = () => {
    callGTM();
    addLogs(
      `${ecoDetalle.title}`,
      `Presiona botón para realizar llamada a ${ecoDetalle.title}`,
      ecoPadre.name
    );
    if (ecoDetalle.id === 9) {
      window.location.href = 'tel:+525588968094';
    }
    else if (ecoPadre.eco === "Cuidados") {
      window.location.href = 'tel:+525588968006';
    }
    else {
      window.location.href = 'tel:+525594178790';
    }
  };

  const validaCitaTipoAgenda = () => {
    if (ecoDetalle.title !== "Coworking") {
      getSchedules(getToken(), "0", ecoDetalle.agendaTipo).then((resp) => {
        if (resp.codigo === 0) {
          let data = resp.data.$values[0];
          setObjAgenda({
            servicio: ecoDetalle.title,
            fechaAgenda: new Date(data.fechaAgenda),
            hora: data.horaAgenda,
            medio: data.tipoLlamada,
            telefono: data.contacto,
            urlVideollamada: data.salaCliente,
          });
          setMostrarMensajeCita(true);
          setTieneCitaAgendada(true);
        }
      });
    } else {
      getSchedulesCow(getToken(), globalDataUser.globalUsuId).then((resp) => {
        if (resp.$values.length !== 0) {
          let data = resp.$values[0];
          setObjAgenda({
            servicio: ecoDetalle.title,
            fechaAgenda: new Date(data.fechaAgendamiento),
            hora: data.horario,
            medio: data.tipoLlamada,
            telefono: data.contacto,
            urlVideollamada: data.salaCliente,
          });
          setMostrarMensajeCita(true);
          setTieneCitaAgendada(true);
        }
      });
    }
  };

  const onMouseOverArrow = () => {
    setArrowIngreso(<IconArrowCard id="arrowCard" color="#fff" />)
  }

  const onMouseOutArrow = () => {
    setArrowIngreso(<IconArrowCard id="arrowCard" color={`var(--${ecoPadre.color} )`} />)
  }

  setInterval(() => {
    setRefreshCoursera(true);
  }, 60000);

  useEffect(() => {
    if (ecoDetalle.id !== 66) return;
    if (linkCoursera === null) {
      saltoMedismart(0)
    }
  }, [linkCoursera])

  useEffect(() => {
    if (ecoDetalle.esAgendamiento) {
      validaCitaTipoAgenda();
    }
    if (!refreshCoursera) return;
    if (ecoDetalle.id !== 59 && ecoDetalle.id !== 64) return;
    let dataCoursera = [];
    let ecosistemaPrograma = globalEcosistemas.find(
      (e) => e.eco === "Eco Coursera" && e.active === true
    );
    try {
      dataCoursera = programasCoursera.find(
        (pc) => pc.id === ecosistemaPrograma.idProgramaCoursera
      );
      getCoursera(
        globalDataUser.globalUsuId,
        getToken(),
        "Eco Coursera",
        dataCoursera
      ).then(([nuevaRuta, status]) => {
        if (status === 401) {
          globalInactivaSesion();
          return;
        }
        if (nuevaRuta?.includes("/")) {
          setLinkCoursera(nuevaRuta);
          setRefreshCoursera(false);
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Inconvenientes en la conexión",
          text: "Volver a intentar en unos instantes.",
        });
      });
    } catch (error) {}
  }, [refreshCoursera]);

  return (
    <>
      {showModalCita && (
        <ModalVerCita
          show={showModalCita}
          setShow={setShowModalCita}
          color={ecoPadre.color}
          ecoDetalle={ecoDetalle}
          objAgenda={objAgenda}
        />
      )}

      {showModalSaltoCoursera && (
        <ModalSaltoCoursera
          show={showModalSaltoCoursera}
          setShow={setShowModalSaltoCoursera}
          color={ecoPadre.color}
          globalDataUser={globalDataUser}
          globalInactivaSesion={globalInactivaSesion}
          ecoPadre={ecoPadre}
        />
      )}

      {((ecoDetalle.id === 76 || ecoDetalle.id === 77) &&
        !plan2.includes(idPlanCuidadoUsuario)) ||
      ((ecoDetalle.id === 78 || ecoDetalle.id === 79) &&
        !plan3.includes(idPlanCuidadoUsuario)) ? (
        <></>
      ) : (
        <div
          className={mostrarMensajeCita ? "eco__card tiene__cita" : "eco__card"}
        >
          <div style={{ position: "relative" }}>
            {mostrarMensajeCita && (
              <div className="div__tiene__cita">Tienes una cita próxima</div>
            )}
            <div className="eco__card-img">
              <img
                className="eco__card-icoprincipal"
                src={ecoDetalle.imgmini}
                alt="imagen eco"
              />
              {/* <div className="eco__card-ico_container ">
                <img
                  className={`eco__card-ico`}
                  src={ecoDetalle.ico}
                  alt="imagen eco icono"
                />
              </div> */}
            </div>
            <div className="eco__card-texto">
              <div className="eco__card-superior">
                <h4
                  style={{ marginBottom: "1em" }}
                  className={`eco__card-title  ${ecoPadre.color}`}
                  dangerouslySetInnerHTML={{ __html: ecoDetalle.title }}
                ></h4>
                {/* <h4 className={`eco__card-title`}>{index + 1}. {ecoDetalle.title}</h4> */}
                {/* <p className='eco__card-subtitulo'>{ecoDetalle.subtitle}</p> */}
                <p
                  dangerouslySetInnerHTML={{ __html: ecoDetalle.desc }}
                  style={{fontSize: "14px", lineHeight: "14px" }}
                  className={`eco__card-descripcion ${
                    ecoDetalle.id === 62 ? "paddingleft" : ""
                  }`}
                />

                {ecoDetalle.title === "Cupones trimestrales" && (
                  <div className="texto_informativo_cp">
                    <p>
                      Este beneficio se activará al cumplir 3 meses a partir de
                      la contratación de tu seguro.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="eco__card-btn" style={{ marginTop: '0' }}>
            {ecosSinSalto.includes(ecoDetalle.id) && (
              <>
                <div
                  className="eco__card-descripcion text-center llamar-telefono"
                  onClick={callNumber}
                >
                  { ecoDetalle.id === 9 ?
                    <p style={{ fontWeight: 'normal', color: `var(--${ecoPadre.color})` }}>APARTA TU ESPACIO: <span style={{ fontFamily: 'Scotia-Bold' }}>55 8896-8094</span></p>
                    :
                    <b>
                      Comunícate al <br /> {ecoPadre.eco === "Salud" ? <>+ 52 (55) 94178790</> : ecoPadre.eco === "Cuidados" && <>+ 52 (55) 88968006</>}
                    </b>
                  }
                </div>
              </>
            )}
            {!ecosSinSalto.includes(ecoDetalle.id) && (
              <>
                <a
                  href={linkCoursera}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ width: "190px", height: "40px", padding: "1rem 2rem", backgroundColor: ecoDetalle.id === 66 && typeof(linkCoursera) !== "string" && "white", pointerEvents: ecoDetalle.id === 66 && typeof(linkCoursera) !== "string" && "none"}}
                  className={`vermas__btn ${ecoPadre.color}`}
                  data-eco={ecoPadre.eco}
                  data-id={ecoDetalle.id}
                  onClick={
                    ecoPadre.eco === "Eco Coursera"
                      ? gotoCoursera
                      : ecoDetalle.id === 38 || ecoDetalle.id === 7 || ecoDetalle.id === 12
                      ? () => setShowModalSaltoCoursera(true)
                      : ecoPadre.eco === "Vida" || ecoPadre.eco === "Salud"
                      ? preGoToEcosistemaModulo
                      : mostrarMensajeCita
                      ? () => setShowModalCita(true)
                      : gotoEcosistemaModulo
                  }
                  onMouseOver={onMouseOverArrow}
                  onMouseOut={onMouseOutArrow}
                >
                  <div className="d-flex justify-content-between">
                    {mostrarMensajeCita ? (
                      <>VER DETALLES</>
                    ) : ecoDetalle.esAgendamiento &&
                      ecoDetalle.id !== 70 &&
                      !mostrarMensajeCita ? (
                      <>AGENDAR</>
                    ) : ecoDetalle.id === 41 || ecoDetalle.id === 44 || ecoDetalle.id === 13 || ecoDetalle.id === 57 ? (
                      <>COMENZAR</>
                    ) : ecoDetalle.id === 66 && typeof(linkCoursera) !== "string" ? (
                      <>CARGANDO...</>
                    ) :
                      <>INGRESAR</>
                    }
                    {arrowIngreso}
                  </div>
                </a>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InEcosistemasCards;
