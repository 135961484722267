export const URL_BACKEND_AUTHS = process.env.REACT_APP_API;
// export const URL_BACKEND_AUTHS = `https://localhost:44311/api`;
// export const URL_BACKEND_AUTHS = `https://desaapivivetuseguro.komased.com/api`;

export const COD_TAG_MANAGER = "GTM-PNNR458";

//codigo de pruebas paty
// export const COD_TAG_MANAGER = ruta === "pre" ? "GTM-KWSW8CCH" : "GTM-KWSW8CCH";

export const COD_RECAPTCHA_V3 = "6LfRF9spAAAAAPYkHKTt_faU77fcDOxkqHSyzaVA";

export const CLI_PUB = "8f6391895c404ab68eb7a8e42eb73587";

export const user = "CARDIF";

export const password = "9B9CB6631B27AA1DF47DB351B497004810015B09";
