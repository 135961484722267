import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FallbackCards from "../../modules/helpers/FallbackCards";

const RouterEmprendimientoMarketing = React.lazy(() =>
  import("./RouterEmprendimientoMarketing")
);
const RouterEmprendimientoCoworking = React.lazy(() =>
  import("./RouterEmprendimientoCoworking")
);
const RouterEmprendimientoLegal = React.lazy(() =>
  import("./RouterEmprendimientoLegal")
);
const EmprendimientoCoursera = React.lazy(() =>
  import(
    "../../modules/homein/emprendimiento/components/coursera/EmprendimientoCoursera"
  )
);
const EmprendimientoScreen = React.lazy(() =>
  import("../../modules/homein/emprendimiento/screens/EmprendimientoScreen")
);
const AgendamientoCalifica = React.lazy(() => 
  import(
    "../../modules/hooks/components/AgendamientoCalifica"
  )
);
const PageNotFound = React.lazy(() => import("../../modules/404/PageNotFound"));

const RouterEmprendimiento = () => {
  return (
    <>
      <Suspense fallback={<FallbackCards />}>
        <Routes>
          <Route path="/coursera" element={<EmprendimientoCoursera />} />
          <Route
            path="/marketing/*"
            element={<RouterEmprendimientoMarketing />}
          />
          <Route
            path="/coworking/*"
            element={<RouterEmprendimientoCoworking />}
          />
          <Route path="/legal/*" element={<RouterEmprendimientoLegal />} />
          <Route 
            path="/califica"
            element={<AgendamientoCalifica color={"verde"}/>}
          />
          <Route path="/" element={<EmprendimientoScreen />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default RouterEmprendimiento;
