import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { COD_RECAPTCHA_V3 } from "../../../enviroments/enviroment";
import { getCodigo, sendCode } from "../../../services/auth";
import { addLogs } from "../../../services/logs_proteccion";
import Timer from "../../helpers/Timer";

import VerificationInput from "react-verification-input";

const AuthRegisterFormCodigo = ({
  errorData,
  mensaje,
  formCode,
  recaptchaRef,
  setMensaje,
  setSpinner,
  form,
  setErrorData,
  setCount,
  setFormCode,
}) => {
  const [codigo, setCodigo] = useState([]);
  const [campos, setCampos] = useState(6);
  const [endTimerStatus, setEndTimerStatus] = useState(false);
  const [timeCode, setTimeCode] = useState(300000);

  const hideCelular = (celular) => {
    let hiddenCelular = "";
    for (let i = 0; i < celular?.length; i++) {
      if (i >= celular.length - 4) {
        hiddenCelular += celular[i];
      } else hiddenCelular += "*";
    }
    return hiddenCelular;
  };

  const handleComplete = (value) => {
    setCodigo(value);
  };

  const handleSubmitCodigo = async (e) => {
    e.preventDefault();

    let camposCodigo = document.querySelectorAll(".codigo-field");
    let codeOtp = parseInt(
      codigo[0] + codigo[1] + codigo[2] + codigo[3] + codigo[4] + codigo[5]
    );
    formCode.code = codeOtp;
    setFormCode(formCode);
    setMensaje("");

    if (isNaN(formCode.code)) {
      camposCodigo.forEach((campo) => {
        campo.style.borderColor = "var(--rojo)";
      });
      setMensaje("Debes proporcionar un código.");
      setErrorData(true);
      return;
    }

    if (formCode.code !== "") {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      if (!token) {
        setMensaje("Captcha inválido, actualizar web");
        return;
      }

      setSpinner(true);

      let objUsuario = {
        id: formCode.id,
        code: formCode.code,
        tokenCaptcha: token,
      };

      addLogs("login", "Ingreso de código", form.identificacion);

      // console.log({ objUsuario })
      sendCode(objUsuario)
        .then((res) => {
          // console.log({ res })
          setSpinner(false);
          if (res.codigo === 0) {
            addLogs("login", "Envío código Exitoso", form.identificacion);
            setMensaje("");
            setErrorData(false);
            setCount(3);
          } else {
            addLogs("login", "Error envío código", form.identificacion);
            camposCodigo.forEach((campo) => {
              campo.style.borderColor = "var(--rojo)";
              campo.style.color = "var(--rojo)";
            });
            setMensaje(
              "El código es incorrecto. Por favor, revisa e intenta de nuevo"
            );
            setErrorData(true);
          }
        })
        .catch((err) => {
          camposCodigo.forEach((campo) => {
            campo.style.borderColor = "var(--rojo)";
            campo.style.color = "var(--rojo)";
          });
          setMensaje(err);
          setErrorData(true);
          setSpinner(false);
        });
    } else {
      setMensaje("Debes proporcionar un código.");
    }
  };

  const endTimer = () => {
    setEndTimerStatus(true);
  };

  const handleSetTimer = (t) => {
    setTimeCode(t);
  };

  const handleChangeInputs = (element, index) => {
    setMensaje("");
    let camposCodigo = document.querySelectorAll(".codigo-field");
    camposCodigo.forEach((campo) => {
      campo.style.borderColor = "#666666";
      campo.style.color = "var(--bs-offcanvas-color)";
    });

    if (isNaN(element.value)) return false;

    setCodigo([...codigo.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const resendCodigo = async () => {
    setEndTimerStatus(false);
    setTimeCode(300000);

    let camposCodigo = document.querySelectorAll(".codigo-field");
    camposCodigo.forEach((campo) => {
      campo.style.borderColor = "#666666";
      campo.style.color = "var(--bs-offcanvas-color)";
    });
    setMensaje("");

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (!token) {
      setMensaje("Captcha inválido, actualizar web");
      return;
    }

    let objUsuario = {
      identificacion: form.identificacion,
      tokenCaptcha: token,
    };

    addLogs("login", "Reenvía código", `${objUsuario}`);

    getCodigo(objUsuario)
      .then((res) => {
        // console.log({ res })
        setSpinner(false);
        if (res.codigo === 0) {
          setErrorData(false);
          addLogs(
            "Activación cuenta usuario",
            "se envió SMS al usuario con el código de activación",
            `${objUsuario}`
          );
          setFormCode({ ...formCode, id: res.data, tokenCaptcha: token });

          setCount(2);
          // TagManager.dataLayer({
          //     dataLayer: {
          //         event: 'recuperarContraseña',
          //         category: 'login',
          //         action: 'Recuperar contraseña',
          //         label: '(not available)',
          //     }
          // })
          return;
        } else if (res.codigo === 1) {
          if (res.mensaje === "No se validó el captcha") {
            setMensaje(res.mensaje);
          } else {
            setMensaje("Correo no válido.");
          }
        } else {
          setMensaje(res.mensaje);
        }
        setErrorData(true);
      })
      .catch((err) => {
        setMensaje(err);
        setErrorData(true);
      });
  };

  useEffect(() => {}, [codigo]);

  return (
    <div className="auth__login">
      <h3 className="auth__title">Recibiste un código</h3>
      <p className="auth__subtitle">
        Enviamos un código a tu celular {hideCelular(formCode.fono)}. Ingrésalo
        a continuación.
        <br />
        <span className="d-none d-lg-block">¿No son tus datos?</span>
        <span className="d-block d-lg-none">
          <br />
          ¿No son tus datos o no llegó el código?
        </span>
        <span style={{ color: "var(--rojo)", fontFamily: "Scotia-Bold" }}>
          Llámanos al 800 801 2402
        </span>
      </p>

      <form onSubmit={handleSubmitCodigo}>
        <div className="campos__codigo">
          <VerificationInput
            name="code"
            length={campos}
            classNames={{
              character: "codigo-field",
            }}
            validChars="0-9"
            passwordMode={true}
            onComplete={(value) => handleComplete(value)}
            onChange={(value, index) => handleChangeInputs(value, index)}
            placeholder=""
          />
          {mensaje && (
            <div
              className="texto__error"
              style={{ textAlign: "center", paddingTop: "1rem" }}
            >
              <p>{mensaje}</p>
            </div>
          )}
        </div>

        <div className="timer__codigo">
          {endTimerStatus ? (
            <p className="auth__subtitle">
              El código expiró{" "}
              <span className="reenviar__codigo" onClick={resendCodigo}>
                Solicitar de nuevo
              </span>
            </p>
          ) : (
            <div>
              <p className="auth__subtitle">
                Tu código expira en{" "}
                <span style={{ color: "var(--rojo)", display: "inline-block" }}>
                  <Timer
                    mill={timeCode}
                    end={endTimer}
                    status={endTimerStatus}
                    handleSetTimer={handleSetTimer}
                  />
                </span>
              </p>
            </div>
          )}
        </div>

        <div>
          <input
            type="submit"
            className={"btn__login completo"}
            disabled={endTimerStatus}
            value="Continuar"
          />

          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={COD_RECAPTCHA_V3}
          />
        </div>
      </form>
    </div>
  );
};

export default AuthRegisterFormCodigo;
