import ReCAPTCHA from 'react-google-recaptcha';
import { COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import { getCodigo, postRegister} from '../../../services/auth';
import { addLogs } from '../../../services/logs_proteccion';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from "react";

const AuthRegisterFormDocumento = ({ errorData, setErrorData, mensaje, setMensaje, recaptchaRef, setSpinner, form, setForm, setFormCode, formCode, setCount }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const handleChange = (e) => {
        document.getElementById(e.target.id).style.borderColor = "var(--borde)"
        document.getElementById(e.target.id).style.color = "var(--bs-offcanvas-color)"
        setMensaje("")
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const gotoLogin = () => {
        navigate('/iniciasesion')
      }

    const handleSubmitDocumento = async (e) => {
        e.preventDefault();

        if (form.identificacion.trim() === "") {
            document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
            setMensaje("Este dato es necesario")
            setErrorData(true)
            return
        }

        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (!form.identificacion.trim().match(pattern)) {
            document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
            document.getElementById("identificacion").style.color = 'var(--rojo)'
            setMensaje('Revisa que contenga @ y los puntos necesarios.')
            setErrorData(true)
            return
        }

        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset()
        if (!token) {
            setMensaje('Captcha inválido, actualizar web')
            setErrorData(true)
            return
        }

        setSpinner(true)

        let objUsuario = {
            identificacion: form.identificacion,
            tokenCaptcha: token
        }

        addLogs('login', 'Registrar usuario', `${objUsuario}`);

        try {

            if ( form.identificacion.trim() !== ""  ) {
            
                let datos = {
                    identificacion: "0",
                    email: form.identificacion,
                    celular: "0",
                    tipoIdentificacion: 7,
                    tokenCaptcha: token,
                };

                postRegister(datos).then(async (rpta) => {
                    if (rpta.Codigo === 1 || rpta.Codigo === 10) {
                        document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                        document.getElementById("identificacion").style.color = 'var(--rojo)'
                        setMensaje(rpta.Mensaje);
                        setErrorData(true)
                        setSpinner(false);

                    } else if (rpta.Codigo === 2) {
                        document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                        document.getElementById("identificacion").style.color = 'var(--rojo)'
                        setMensaje(
                        "Encontramos una cuenta activa para este correo.\nPor favor, inicia sesión"
                        );
                        setErrorData(true)
                        setSpinner(false);

                    } else if (rpta.Codigo === 3) {
                        document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                        document.getElementById("identificacion").style.color = 'var(--rojo)'
                        setMensaje("Producto contratado inactivo");
                        setSpinner(false);
                    } else if (rpta.Codigo === 4) {
                        document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                        document.getElementById("identificacion").style.color = 'var(--rojo)'
                        setMensaje("Tu seguro aún no está activo. Podrás activar tu cuenta próximamente.");
                        setErrorData(true)
                        setSpinner(false);

                    } else if (rpta.Codigo === 0) {
                        // CONFORME
                        setMensaje("");
                        getCodigo(objUsuario).then(res => {
                            // console.log({ res })
                            setSpinner(false)
                            if (res.codigo === 0) {
        
                                setMensaje("")
                                setErrorData(false)
                                setFormCode({ ...formCode, id: res.data, fono: rpta.user.fono, tokenCaptcha: token })
                                addLogs("Activación cuenta usuario", "se envió SMS al usuario con el código de activación", `${objUsuario}`)
        
                                setCount(2)
                                return
                            } else if (res.codigo === 1) {
                                setErrorData(true)
                                if (res.mensaje === 'No se validó el captcha') {
                                    setMensaje(res.mensaje)
                                } else {
                                    document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                                    document.getElementById("identificacion").style.color = 'var(--rojo)'
                                    setMensaje('Utiliza el correo que registraste al adquirir tu seguro')
                                }
                            } else {
                                document.getElementById("identificacion").style.borderColor = 'var(--rojo)'
                                document.getElementById("identificacion").style.color = 'var(--rojo)'
                                setMensaje(res.mensaje)
                            }
                            setErrorData(true)
                        })
                    }
                });
            } else {
                Swal.fire({
                    title: "Problemas!",
                    text: "Hubo un problema en la autenticación",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                });
                setErrorData(true)
                setMensaje("Este dato es necesario");
            }
        } catch (err) {
            setMensaje(err)
            setErrorData(true)
            setSpinner(false)
        }
    }

    useEffect(() => {
        if (location.state && location.state.document) {
            setForm({ identificacion: location.state.document });
        }
    }, [location.state]);

    return (
        <div className="auth__login">

            <h3 className="auth__title">Activa tu cuenta</h3>
            <p className="auth__subtitle" >Ingresa los mismos datos que registraste al adquirir tu seguro Scotiabank</p>

            <form onSubmit={handleSubmitDocumento}>

                <div className="auth__login-label" >Correo electrónico</div>
                <div className="auth__login-group" >
                    <input
                        type="text"
                        className="auth__login-docompleto"
                        placeholder="Ingresa tu correo electrónico"
                        name="identificacion"
                        id="identificacion"
                        value={form.identificacion}
                        onChange={handleChange}
                    />
                </div>
                {
                    errorData &&
                    <div className='texto__error'>
                        <p>{mensaje}</p>
                    </div>
                }

                <div>
                    <input
                        type="submit"
                        className="btn__login completo"
                        value="Continuar"
                    />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={COD_RECAPTCHA_V3}
                    />
                </div>
            </form>
            <div className='auth__rutas' style={{ justifyContent: 'space-between', gap: '2rem'}}>
                <p className="auth__logueo-text custom_checkbox">¿Ya tienes una cuenta?</p>
                <p className="auth__logueo-btn" onClick={gotoLogin}>Inicia sesión</p>
            </div>
        </div>
    )
}

export default AuthRegisterFormDocumento