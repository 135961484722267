import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import "../hooks/ecostyles.css";
import "../hooks/ecobodysuperior.css";
import useBotons from "./useBotons";

const usePresentationNew = (
  botonActivo = true,
  textoBoton = "",
  idModuloEcosistema = 1
) => {
  const { pathname } = useLocation();
  const { globalDetalleEcosistemas } = useContext(AuthContext);
  const { iniciaProcesoBtn } = useBotons();
  const [direccion, setDireccion] = useState([]);

  useEffect(() => {
    let dir = pathname.split("/");
    setDireccion(dir);
  }, []);

  const presentacionInMigas = () => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];
    return (
      <>
        <div className="miga__box_c">
          <div className="miga__texto">
            <NavLink
              to={`/${direccion[1]}/${direccion[2]}`}
              className="miga__link"
            >
              Inicio
            </NavLink>
            {direccion
              .filter((d, i) => i === 3)
              .map((dir, index) => {
                return (
                  <div key={index}>
                    <span>{" >"}</span>

                    <NavLink
                      to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                      className="miga__link"
                    >
                      {data.title === "Mi Score de crédito"
                        ? "Mi Score"
                        : data.title === "Cursos online con certificación" 
                        ? "Calificar asesoría" 
                        : data.title === "Simulador de habilidades"
                        ? "Test de habilidades"
                        : data.title}
                    </NavLink>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const presentacionIn = (
    ecosys,
    service,
    acc,
    siguienteLink,
    ecosistema,
    accion = "",
    motivoLegal,
    setMotivoLegal,
    setEtapa
  ) => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];
    let fondo = true;
    let check = false;
    if (idModuloEcosistema === 12) {
      // fondo = false;
    }
    if (idModuloEcosistema === 3) {
      check = true;
    }
    return (
      <>
        <div className="miga__box_c">
          <div className="miga__texto">
            <NavLink
              to={`/${direccion[1]}/${direccion[2]}`}
              className="miga__link"
            >
              Inicio
            </NavLink>
            {direccion
              .filter((d, i) => i >= 3)
              .map((dir, index) => {
                return (
                  <div key={index}>
                    <span>{" >"}</span>

                    <NavLink
                      to={`/${direccion[1]}/${direccion[2]}/${direccion[3]}`}
                      className="miga__link"
                    >
                      {data.title === "Mi Score de crédito"
                        ? "Mi Score"
                        : data.title}
                    </NavLink>
                  </div>
                );
              })}
          </div>
        </div>
        <section
          className="eco__presentacion sinfondoresponsive"
          style={data.id === 8 ? {
            backgroundImage: 'none',
            backgroundSize: "contain",
            backgroundPosition: "right 50px bottom 0px ",
            backgroundRepeat: "no-repeat",
            alignItems: "center",
          } : {
            backgroundImage: 'none',
            backgroundSize: "contain",
            backgroundPosition: "right 50px bottom 0px ",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="fondoblanco" style={{width: (data.id === 43 || data.id === 40) && 'auto'}}>
            <div className="eco__presentacion-textos izquierda">
              <div className="eco__textos">
                {data.title === "Red de servicios de salud" ? (
                  <h2 className="eco__textos-titlegrande">
                    Direccionamiento y uso de red de servicios de salud
                  </h2>
                ) : (
                  <>
                    {data.title === "¿Qué es Coursera?" ? (
                      <h2 className="eco__textos-titlegrande">
                        Da el siguiente paso hacia tus metas personales y
                        profesionales con Coursera
                      </h2>
                    ) : data.title === "Mi Score de crédito" ? (
                      <h2 className="eco__textos-titlegrande">Mi Score</h2>
                    ) : data.title === "Protección Online" ? (
                      <h2 className="eco__textos-titlegrande morado">Protección Online - ¡Protege a tu familia!</h2>
                    ) : (
                      <h2
                        style={{ color: data.colorPrincipal }}
                        className={`eco__textos-titlegrande ${
                          idModuloEcosistema === 65 && "verde"
                        }`}
                      >
                        {data.title}
                      </h2>
                    )}
                  </>
                )}

                {idModuloEcosistema === 18 && (
                  <div className="addpadding"></div>
                )}
                {idModuloEcosistema === 13 ? (
                  <></>
                ) : (
                  <p
                    className="eco__textos-descripmini"
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: data.textoIn }}
                  ></p>
                )}

                {
                  idModuloEcosistema === 43 &&
                  <div className="eco__textos-descripmini descripcion-buro" style={{ width: "36vw" }}>
                    <br/>
                    <p>Recibirás alertas sobre:</p>
                    <div className="lista__alertas">
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-creditos.svg" alt="" style={{ paddingRight: '1rem' }}/>Créditos nuevos y eliminados</p>
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-domicilios.svg" alt="" style={{ paddingRight: '1rem' }}/>Nuevos domicilios: Particular o de trabajo</p>
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-historial.svg" alt="" style={{ paddingRight: '1rem' }}/>Consultas a tu historial</p>
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-datos-generales.svg" alt="" style={{ paddingRight: '1rem' }}/>Cambios en tus datos generales</p>
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-creditos-aclaracion.svg" alt="" style={{ paddingRight: '1rem' }}/>Créditos en aclaración</p>
                      <p><img src="/assets/img/ecosistemas/proteccion-financiera/iconos-alertas/ico-aclaraciones.svg" alt="" style={{ paddingRight: '1rem' }}/>Aclaraciones resueltas</p>
                    </div>
                    <br/>
                    <p>Durante la vigencia de tu póliza podrás contar con este beneficio <b>sin costo adicional</b> para ti.<br/>Al término de la vigencia de tu seguro deberás activar nuevamente tus alertas.</p>
                  </div>
                  
                }

                {(idModuloEcosistema === 40 || idModuloEcosistema === 17 || idModuloEcosistema === 10) && (
                  <div className="row row-cols-1 legal__consultar">
                    <div className="col">
                      <label>¿Qué temas deseas consultar?</label>
                      <textarea value={motivoLegal} onChange={(e) => setMotivoLegal(e.target.value)} placeholder="Ej: Despido injustificado" maxLength={400} />
                      <p style={{ textAlign: 'end' }}>Máximo 400 caracteres.</p>
                    </div>
                  </div>
                )}

                {check ? (
                  <>
                    <div className="checkAlertas salud_alerta-check">
                      <input type="checkbox" />
                      <p style={{ marginLeft: 6, fontSize: "16px" }}>
                        Autorizo la{" "}
                        <span style={{ color: "var(--rojo)" }}>
                          {" "}
                          consulta, reporte y procesamiento de datos financieros
                          a centrales de riesgo.
                        </span>
                      </p>
                    </div>
                  </>
                ) : null}

                {idModuloEcosistema === 48 ? (
                  <p className="eco__textos-descripmini">
                    <br />
                    Asistiéndote desde la palma de tu mano.
                  </p>
                ) : (
                  <></>
                )}

                {idModuloEcosistema === 51 ? (
                  <p className="eco__textos-descripmini">
                    • Resumen del comportamiento crediticio en un sólo número a
                    través de "mi score".
                    <br />
                    • Conocimiento de los factores que influyen en el resultado.
                    <br />
                    • Recomendaciones para mejorar la puntuación.
                    <br />
                    • Comparativo de su situación frente a la población
                    mexicana.
                    <br />
                  </p>
                ) : (
                  <></>
                )}

                {idModuloEcosistema === 52 ? (
                  <p className="eco__textos-descripmini">
                    <img
                      src="/assets/img/ecosistemas/proteccion-elemental/interna/uber-logo.png"
                      alt="imagen ecosistema"
                    />
                    <br></br>
                    Este servicio cubre un (1) evento al año y hasta por un
                    monto máximo de $300 pesos.
                    <br />
                  </p>
                ) : (
                  <></>
                )}
                { botonActivo &&
                  <div className={`proteccion__btns ${service === 'PROTECCIONONLINE' && 'd-none d-md-block'}`}>
                    {iniciaProcesoBtn(
                      ecosys,
                      service,
                      acc,
                      botonActivo,
                      textoBoton,
                      data.colorPrincipal,
                      fondo,
                      siguienteLink,
                      data.descrip,
                      "Banner Principal",
                      ecosistema,
                      data.paginaBtn,
                      accion,
                      setEtapa
                    )}
                  </div>
                }
                {idModuloEcosistema === 48 ? (
                  <p className="eco__textos-descripmini">
                    Ponemos a tu disposición una red de servicios de salud al
                    lugar donde te encuentres.
                  </p>
                ) : (
                  <></>
                )}
              </div>
              {idModuloEcosistema === 2 && (
                <div className="eco__textos">
                  <p className="eco__textos-descripmini-bottom">
                    <strong>Importante</strong>
                  </p>
                  {
                    <p style={{ fontSize: "14px" }}>
                      El score de crédito es suministrado por Sentinel, central
                      de información crediticia.
                    </p>
                  }
                </div>
              )}
            </div>
          </div>
          <div className="eco__presentacion-img d-none d-xl-block" style={{alignContent: "baseline", marginTop: "7rem", marginLeft: data.id === 40 && "4rem"}}>
              <img src={data.img}></img>
          </div>
        </section>
      </>
    );
  };

  const presentacionInBox = () => {
    let data = globalDetalleEcosistemas.filter(
      (g) => g.id === idModuloEcosistema
    )[0];

    return (
      <>
        <div className="box__presentacion__extra" style={{ marginTop: "50px" }}>
          <div className="presentacion__in_div" style={{ width: "60%" }}>
            <div className="presentacion__in_descrip">
              <h2>{data.presentacion.titulo}</h2>
              <div>{data.presentacion.texto1}</div>
              <div>{data.presentacion.texto2}</div>
            </div>
          </div>
          <div className="presentacion__in_div" style={{ width: "40%" }}>
            <img src={data.presentacion.img} />
          </div>
        </div>
      </>
    );
  };

  return { presentacionIn, presentacionInBox, presentacionInMigas };
};

export default usePresentationNew;
