import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import useTitle from "../../../hooks/useTitle";
import PrincipalPresentacion from "../components/PrincipalPresentacion";

import PrincipalOfertas from "../components/PrincipalOfertas";
import PrincipalPasos from "../components/PrincipalPasos";
import PrincipalPreguntas from "../components/PrincipalPreguntas";
import LazyPrincipalCarousel from "../components/LazyPrincipalCarousel";
import LazyPrincipalBanner from "../components/LazyPrincipalBanner";
import LazyPrincipalContacto from "../components/LazyPrincipalContacto";
import PrincipalContactoResumen from "../components/PrincipalContactoResumen";

const HomeOutScreen = () => {
  useTitle({ title: "Landing Vive tu Seguro" });
  const { globalAutenticado } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (globalAutenticado) {
      navigate("/ecosistema");
    }
  }, []);

  return (
    <div>
      <main>
        <PrincipalPresentacion />
        <PrincipalOfertas />
        <LazyPrincipalCarousel />
        <PrincipalPasos />
        <PrincipalPreguntas />
        <LazyPrincipalBanner />
        <div style={{ background: "var(--bg_claro)"}}>
          <PrincipalContactoResumen />
        </div>
      </main>
    </div>
  );
};

export default HomeOutScreen;
